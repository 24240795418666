<template>
  <ul class="progress-status">
    <li
      v-for="(item, index) in statusTexts"
      :key="index"
      :class="{ active: activeIndex === index }"
    >
      <div class="text">{{ item }}</div>
    </li>
  </ul>
</template>

<script>
export default {
  name: "ProgressStatus",
  data() {
    return {
      statusTexts: [
        "배포신청",
        "미디어매칭",
        "기사 최종확인",
        "배포 진행",
        "배포 완료",
      ],
    };
  },
  props: {
    activeIndex: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style scoped>
.progress-status {
  display: flex;
  position: absolute;
  top: 51px;
  left: 561px;
}

.progress-status li {
  width: 80px;
  height: 4px;
  background: #d8d8d8;
  position: relative;
  list-style: none;
}

.progress-status li .text {
  position: absolute;
  top: -20px;
  left: 0;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: -0.02em;
  white-space: pre;
  transform: translate3d(-50%, 0, 0);
}

.progress-status li:last-child .text {
  right: 0;
  left: auto;
  transform: translate3d(50%, 0, 0);
}

.progress-status li::before {
  content: "";
  position: absolute;
  bottom: -4px;
  left: -5px;
  display: block;
  width: 12px;
  height: 12px;
  background: #d8d8d8;
  border-radius: 12px;
}

.progress-status li.active::before {
  bottom: -6px;
  width: 16px;
  height: 16px;
  background: linear-gradient(
    130.58deg,
    #00ff76 10.93%,
    #00fd78 28.66%,
    #00f981 40.72%,
    #00f391 51.36%,
    #00ee98 57.03%,
    #00e0ad 65.54%,
    #00cbd0 75.47%,
    #00bbea 81.86%
  );
}

.progress-status li.active .text {
  font-weight: 700;
  font-size: 12px;
}

.progress-status li:last-child {
  width: 0;
}
</style>
