<template>
  <div class="wrapper">
    <h2>
      <img
        srcSet="/images/img-logo@2x.png 2x"
        src="/images/img-logo.png"
        alt="AI PR"
      />
    </h2>
    <h1>
      <img
        srcSet="/images/img-main-title@2x.png 2x"
        src="/images/img-main-title.png"
        alt="우리회사 PR, 이토록 쉬워집니다."
      />
    </h1>

    <button class="btn-google" @click="checkSignUp">
      <img
        srcSet="/images/ico-google@2x.png 2x"
        src="/images/ico-google.png"
        alt=""
      />
      Sign Up with Google
    </button>
  </div>
</template>

<script>
export default {
  name: "EntryPage`",
};
</script>

<script setup>
import { useRouter } from 'vue-router'
const router = useRouter();
const checkSignUp = ()=> {
  router.push('/register')
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background-color: #333333;
}

h2 {
  margin-bottom: 30px;
}

img {
  vertical-align: top;
}

.btn-google {
  width: 345px;
  height: 54px;
  margin-top: 164px;
  background: #ffffff;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.084), 0px 2px 3px rgba(0, 0, 0, 0.168);
  border-radius: 10px;
  font-size: 20px;
  border-width: 0;
  cursor: pointer;
}

.btn-google img {
  margin-right: 15px;
}
</style>
