<template>
  <div class="wrapper">
    <h2>
      추가정보만 입력하면<br />
      바로 시작하실 수 있어요.
    </h2>
    <form @submit.prevent="handleSubmit">
      <div class="field-group">
        <div class="label-wrapper">
          <label for="user-name">담당자명</label>
        </div>
        <Input
          id="user-name"
          v-model="userName"
          placeholder="담당자명을 입력해주세요"
        />
      </div>

      <div class="field-group">
        <div class="label-wrapper">
          <label for="company-name">회사명</label>
        </div>
        <Input
          id="company-name"
          placeholder="회사명 또는 브랜드명을 입력해주세요"
          v-model="companyName"
        />
      </div>

      <div class="field-group">
        <div class="label-wrapper">
          <label for="user-mobile">담당자 휴대전화</label>
        </div>
        <Input
          type="tel"
          id="user-mobile"
          v-model="userMobile"
          placeholder="휴대전화 번호를 입력해주세요"
        />
      </div>

      <div class="field-group">
        <div class="label-wrapper">
          <label for="email">이메일</label>
          <div class="disclaimer">
            * <span class="blank-p">gmail.com, </span>naver.com 등 일반 이메일은 사용이 불가능합니다
          </div>
        </div>
        <div class="field-wrapper">
          <Input
            type="email"
            id="email"
            v-model="email"
            :disabled="isVerified"
            placeholder="이메일을 입력해주세요"
          />
          <Button
            msg="메일인증"
            type="button"
            class="md"
            :disabled="isSendingEmail"
            @click="handleSendVerificationCode"
          />
          <div class="disclaimer" v-if="this.sentVerificationCode">
            *입력하신 이메일로 인증번호를 전송했습니다.
          </div>
        </div>
        <div
          class="field-wrapper field-verification"
          v-if="this.sentVerificationCode"
        >
          <Input
            type="tel"
            id="verification-code"
            :disabled="isVerified"
            v-model="verificationCode"
            placeholder="인증번호 입력"
          />
          <Button
            msg="인증하기"
            type="button"
            class="md"
            :disabled="clickAuth"
            @click="handleVerifyEmail"
          />
          <div class="disclaimer" v-if="this.isVerified">
            *이메일 인증이 완료되었습니다.
          </div>
        </div>
      </div>

      <div class="field-group">
        <div class="label-wrapper">
          <label for="password">비밀번호</label>
        </div>
        <Input
          type="password"
          id="password"
          v-model="password"
          placeholder="비밀번호를 입력해주세요"
        />
      </div>

      <div class="field-group">
        <div class="label-wrapper">
          <label for="password-confirm">비밀번호 확인</label>
        </div>
        <Input
          type="password"
          id="password-confirm"
          v-model="passwordConfirm"
          placeholder="비밀번호를 입력해주세요"
        />
        <div class="error-message" v-if="this.passwordMismatch">
          비밀번호가 틀립니다.
        </div>
      </div>

      <div class="field-group">
        <div class="label-wrapper">
          <label for="job-role">담당하고 계신 업무는 무엇인가요?</label>
        </div>
        <Select
          id="job-role"
          :options="jobRoleOptions"
          v-model="jobRole"
          placeholder="담당하고 계신 업무를 선택해주세요"
        />
      </div>

      <div>
        <input
            type="checkbox"
            :id="agree-terms"
            :checked="agreeTerms"
            @change="handleChangeAgreeTerms"
            v-model="agreeTerms"
        />
        <span class="terms-container">
          <a href="https://terms.1zlabs.com/aipr/" target="_blank">서비스 이용약관</a>과
          <a href="https://terms.1zlabs.com/aipr-1/" target="_blank">개인정보 수집·이용·보관</a>에 <br class="blank-t">동의합니다.
        </span>
      </div>

      <div class="divider" />
      <div class="btn-group">
        <Button msg="계정만들기" class="full-width" :disabled="onRegister"/>
      </div>
    </form>
  </div>
</template>

<script>
import Input from "../../common/Input.vue";
import Select from "../../common/Select.vue";
import Button from "../../common/Button.vue";
import { JOB_ROLE_OPTIONS } from "@/constants";
import {createUserAccount} from "@/firebase/firebase_auth_google";
import {checkAuthEmail, registerUserApi, sendAuthEmail} from "@/api/aipr_api";
import {assignUserData} from "@/store/store";

export default {
  name: "RegisterPage`",
  components: {
    Input,
    Select,
    Button,
  },
  data() {
    return {
      onRegister: false,
      userName: "",
      companyName: "",
      userMobile: "",
      password: "",
      passwordConfirm: "",
      sentVerificationCode: false,
      verificationCode: "",
      isVerified: false,
      email: "",
      agreeTerms: false,
      jobRole: "",
      jobRoleOptions: JOB_ROLE_OPTIONS,
      isSendingEmail: false,
      clickAuth: false,
    };
  },
  computed: {
    passwordMismatch() {
      return (
        this.password !== "" &&
        this.passwordConfirm !== "" &&
        this.password !== this.passwordConfirm
      );
    },
  },
  methods: {
    async handleSendVerificationCode() {
      this.isSendingEmail = true;
      let res = await sendAuthEmail(this.email);
      alert(res.message);
      if(res.result==="success"){
        this.sentVerificationCode = true;
      }else{
        this.email = "";
      }
      this.isSendingEmail = false;
    },
    async handleVerifyEmail() {
      this.clickAuth = true;
      let res = await checkAuthEmail(this.email, this.verificationCode);
      alert(res.message);
      if(res.result === "success"){
        this.isVerified = true;
        this.isSendingEmail = true;
      }else{
        this.clickAuth = false;
      }
    },
    handleChangeAgreeTerms(e) {
      this.agreeTerms = e.target.checked;
    },
    isValidFormData(){
      if(!this.userName){
        alert("이름을 입력해주세요.");
        return false
      }
      if (!this.companyName) {
        alert("회사명을 입력해주세요.");
        return false
      }
      if (!this.userMobile) {
        alert("휴대폰 번호를 입력해주세요.");
        return false
      }

      if (!this.email) {
        alert("이메일을 입력해주세요.");
        return false
      }

      if (!this.isVerified) {
        alert("이메일 인증을 해주세요.");
        return false
      }

      if (!this.password) {
        alert("비밀번호를 입력해주세요.");
        return false
      }
      if (!this.passwordConfirm) {
        alert("비밀번호 확인을 입력해주세요.");
        return false
      }
      //password mismatch
      if (this.password !== this.passwordConfirm) {
        alert("비밀번호가 일치하지 않습니다.");
        return false
      }

      if (!this.jobRole) {
        alert("담당하고 계신 업무를 선택해주세요.");
        return false
      }
      if (!this.agreeTerms) {
        alert("서비스 이용약관과 개인정보 수집·이용·보관에 동의해주세요.");
        return false
      }
      return true;
    },
    async handleSubmit() {
      this.onRegister = true;
      //call createUserAccount API
      if(this.isValidFormData()){
        const googleAuthUser = await createUserAccount(this.email, this.password);
        if(googleAuthUser){
          const res = await registerUserApi(this.userName,
              this.companyName, this.email,
              this.userMobile, this.jobRole,
              this.jobRole, googleAuthUser.uid);
          if(res.result === "success") {
            assignUserData(this.$store, res.data);
            window.gtag('event', 'complete_registration', {send_to: "G-Z0HD1NJ86X", company_name: this.companyName})
            window.fbq('track', 'CompleteRegistration')
            this.$router.push('/create');
          }else{
            alert(res.message)
          }
        }else{
          alert("회원가입에 실패했습니다.");
        }
      }
      this.onRegister = false;
    },

  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">


@media (max-width: 480px) {
  .wrapper {
    width: 100vw;
    padding: 60px 40px 40px;
    box-sizing: border-box;
    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 17px;
  }

  .wrapper h2 {
    margin-bottom: 36px;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: -0.02em;
    text-align: left;
  }

  .field-group {
    position: relative;
    margin-bottom: 24px;

    .label-wrapper {
      margin-bottom: 4px;
      text-align: left;
    }

    .disclaimer {
      position: absolute;
      top: 0;
      right: 0;
      font-weight: 700;
      font-size: 10px;
      line-height: 14px;
      padding-top: 0.4rem;
      letter-spacing: -0.02em;

      color: #000000;
    }

    .field-wrapper {
      display: flex;
      gap: 0 27px;
      position: relative;

      .disclaimer {
        padding-left: 18px;
        top: auto;
        right: auto;
        position: absolute;
        bottom: -20px;
        left: 0;
        font-weight: 500;
      }

      &.field-verification {
        margin-top: 37px;
        margin-bottom: 44px;
      }

      .btn {
        flex: 0 0 100px;
        width: 100px;
        height: 58px;
        font-size: 15px;
      }
    }
  }

  .field-group .label-wrapper label {
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.02em;
    color: #000000;
  }

  .error-message {
    margin-top: 6px;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: -0.02em;
    text-align: left;
    color: #ed4337;
  }

  .divider {
    margin: 22px 0;
    width: 100%;
    height: 1px;
    background: #ccc7c7;
  }
  .terms-container {
    font-size: 0.85rem;
    letter-spacing: -0.05rem;
    margin-left: 8px;
  }

  .blank-p{
    display:none;
  }
}

@media (min-width: 481px) {
  .wrapper {
    width: 568px;
    padding: 52px 64px 34px;
    margin: 85px auto;
    box-sizing: border-box;
    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 17px;
  }

  .wrapper h2 {
    margin-bottom: 36px;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: -0.02em;
    text-align: left;
  }

  .field-group {
    position: relative;
    margin-bottom: 24px;

    .label-wrapper {
      margin-bottom: 4px;
      text-align: left;
    }

    .disclaimer {
      position: absolute;
      top: 0;
      right: 0;
      font-weight: 700;
      font-size: 10px;
      line-height: 14px;
      letter-spacing: -0.02em;
      padding-top: 0.4rem;


      color: #000000;
    }

    .field-wrapper {
      display: flex;
      gap: 0 27px;
      position: relative;

      .disclaimer {
        padding-left: 18px;
        top: auto;
        right: auto;
        position: absolute;
        bottom: -20px;
        left: 0;
        font-weight: 500;
      }

      &.field-verification {
        margin-top: 37px;
        margin-bottom: 44px;
      }

      .btn {
        flex: 0 0 100px;
        width: 100px;
        height: 58px;
        font-size: 15px;
      }
    }
  }

  .field-group .label-wrapper label {
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.02em;
    color: #000000;
  }

  .error-message {
    margin-top: 6px;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: -0.02em;
    text-align: left;
    color: #ed4337;
  }

  .divider {
    margin: 22px 0;
    width: 100%;
    height: 1px;
    background: #ccc7c7;
  }
  .terms-container {
    font-size: 16px;
    margin-left: 14px;
  }
  .blank-t{
    display:none;
  }
}
</style>
