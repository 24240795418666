<template>
  <div class="ai-press-result-wrapper">
    <div class="result-wrapper">
      <div>
        <input type="text" class="result-title" />
      </div>
      <div>
        <textarea class="result-contents" />
      </div>
    </div>
    <div class="btn-group" style="margin-bottom: 60px">
      <Button msg="직접 수정하기" class="light md" />
    </div>
    <div class="btn-group">
      <Button msg="저장하기" class="btn-edit" disabled />
      <Button msg="배포하기" class="btn-edit" disabled />
    </div>
  </div>
</template>

<script>
import Button from "@/components/common/Button.vue";

export default {
  name: "PanelAIPressResult`",
  props: {
    title: {
      type: String,
    },
    contents: {
      type: String,
    },
  },
  components: {
    Button,
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.ai-press-result-wrapper {
  width: 100%;
}

.result-title {
  width: 100%;
  height: 67px;
  line-height: 67px;
  padding: 0 22px;
  border-width: 0;
  appearance: none;
  outline: 0;
  border-bottom: 1px solid #ccc7c7;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: -0.02em;
  color: #000000;
  box-sizing: border-box;
}

.result-contents {
  width: 100%;
  height: 400px;
  padding: 24px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  border-width: 0;
  box-sizing: border-box;
  box-sizing: border-box;
  appearance: none;
  outline: 0;
}

.result-wrapper {
  width: 100%;
  height: 580px;
  margin-bottom: 18px;
  background: #ffffff;
  border-radius: 12px;
  overflow: hidden;
}

.btn-group {
  display: flex;
  gap: 0 18px;
  justify-content: center;

  .btn-edit {
    width: 190px;
  }
}
</style>
