<template>
  <form @submit.prevent="submitForm">
    <div class="field-group">
      <div class="label-wrapper">
        <label for="company-name"
          >* 회사명 / 브랜드명 <span class="required">(필수)</span>
        </label>
      </div>
      <Input
        id="company-name"
        placeholder="회사명 또는 브랜드명을 입력해주세요"
        v-model="companyName"
      />
      <FormErrorMessage
        v-if="hasError('companyName')"
        message="필수 입력사항입니다."
      />
    </div>

    <div class="field-group-flex">
      <div class="field-group" style="flex: 0 0 158px">
        <div class="label-wrapper">
          <label for="leader-name"
            >* 대표자 이름 <span class="required">(필수)</span>
          </label>
        </div>
        <Input id="leader-name" maxlength="7" v-model="leaderName" />
        <FormErrorMessage
          v-if="hasError('leaderName')"
          message="필수 입력사항입니다."
        />
      </div>
      <div class="field-group" style="flex: 0 0 294px; margin-left: auto">
        <div class="label-wrapper">
          <label for="web-page">회사 / 브랜드 홈페이지 링크</label>
        </div>
        <Input type="url" id="web-page" v-model="webPage" />
      </div>
    </div>

    <div class="field-group">
      <div class="label-wrapper">
        <label for=""
          >* 보도자료 주제 <span class="required">(필수)</span>
        </label>
        <div class="press-release-list">
          <div v-for="(item, index) in subjectList" :key="index">
            <input
              type="radio"
              name="press-release-item"
              :id="('press-release-', index)"
              :value="item"
              v-model="pressReleaseSubject"
            />
            <label :for="('press-release-', index)">{{ item.title }}</label>
          </div>
        </div>
      </div>
      <Textarea
          id="news"
          placeholder="알리고자 하는 회사 소식을 2-3문장으로
간략하게 작성해주세요."
          v-model="news"
      />
      <FormErrorMessage
        v-if="hasError('pressReleaseSubject') || hasError('news')"
        message="필수 입력사항입니다."
      />
    </div>

    <div class="field-group">
      <div class="label-wrapper">
        <label for="">자료 첨부하기</label>
      </div>
      <div class="field-file">
        <div class="disclaimer">
          보도자료에 실릴 이미지를 첨부합니다. <span>(필수)</span>
        </div>
        <div class="field-wrapper">
          <div class="fake-input">
            <span v-if="imageForPress" class="active">{{
                imageForPress.name
              }}</span>
            <img v-else-if="attachment_url1" :src="attachment_url1" class="attachment-thumbnail"/>
            <span v-else>첨부할 파일을 이곳에 넣어주세요</span>

            <Input
                type="file"
                id="image-for-press"
                @change="handleImageForPress($event)"
            />
          </div>
          <Button
              msg="파일선택"
              class="md light"
              @click="selectImageForPress()"
          />
        </div>
        <FormErrorMessage
          v-if="hasError('imageForPress')"
          message="필수 입력사항입니다."
        />
      </div>

      <div class="field-file" style="display:none;">
        <div class="disclaimer">
          (선택) 추가로 제공할 미디어킷 또는 회사소개서를 넣어주세요.
        </div>
        <div class="field-wrapper">
          <div :class="({ active: !!fileForPress }, 'fake-input')">
            <span v-if="fileForPress" class="active">{{
                fileForPress.name
              }}</span>
            <img v-else-if="attachment_url2" :src="attachment_url2" class="attachment-thumbnail"/>
            <span v-else>첨부할 파일을 이곳에 넣어주세요</span>
            <Input
                type="file"
                id="file-for-press"
                @change="handleFileForPress($event)"
            />
          </div>
          <Button
              msg="파일선택"
              class="md light"
              @click="selectFileForPress()"
          />
        </div>
      </div>
    </div>

    <div class="btn-group">
      <Button :msg="generatePrCTA" @click="generatePrContent" />
    </div>
  </form>
</template>

<script>
import { defineComponent } from "vue";
import Input from "@/components/common/Input.vue";
import Textarea from "@/components/common/Textarea.vue";
import Button from "@/components/common/Button.vue";
import {generatePrContentApi, savePrContentApi} from "@/api/aipr_api";
import FormErrorMessage from "@/components/common/FormErrorMessage.vue";
export default defineComponent({
  name: "PanelForm",
  components: {
    Input,
    Textarea,
    Button,
    FormErrorMessage,
  },
  props: {
  },
  data() {
    return {
      generatePrCTA: "보도자료 생성하기",
      prId: null,
      trialCount: 0,
      currentRequestId: null,
      companyName: "",
      leaderName: "",
      webPage: "",
      pressReleaseSubject: "",
      news: "",
      attachment_url1: null,
      attachment_url2: null,
      imageForPress: null,
      lastImageForPress: null,
      fileForPress: null,
      subjectList: [
        {title: "투자 유치", value: "fundraising"},
        {title: "성과 달성", value: "achievements"},
        {title: "제품 출시", value: "product_launch"},
        {title: "인사 및 채용", value: "human_resource"},
        {title: "해외 진출", value: "global_expansion"},
        {title: "수상", value: "awards"},
        {title: "일반홍보", value: "pr_general"}
      ],
    };
  },
  methods: {
    submitForm() {
      this.isDirty = true;

      if (
        !this?.companyName ||
        !this?.leaderName ||
        !this?.pressReleaseSubject ||
        !this?.news ||
        !this?.imageForPress
      ) {
        return false;
      }

      const formData = {
        companyName: this?.companyName,
        leaderName: this?.leaderName,
        webPage: this?.webPage,
        pressReleaseSubject: this?.pressReleaseSubject,
        news: this?.news,
        imageForPress: this?.imageForPress,
        fileForPress: this?.fileForPress,
      };
      console.log(formData);
    },
    makeApiParamsFromFormData(){
      const newFormData = {};
      newFormData.aipr_press_release_id=this.$data.prId;
      newFormData.company_name=this.companyName;
      newFormData.ceo_name=this.leaderName;
      newFormData.homepage_url=this.webPage;
      newFormData.subject_type=this.pressReleaseSubject;
      newFormData.summary_input=this.news;
      if(this.imageForPress != this.lastImageForPress){
        newFormData.attachment_url1=this.imageForPress;
      }
      newFormData.attachment_url2=this.fileForPress;
      newFormData.template_name=this.pressReleaseSubject;
      newFormData.request_id=this.currentRequestId;
      return newFormData;
    },
    async generatePrContent() {
      if(!this.isValidateForm()){
        return;
      }
      this.$data.currentRequestId = Math.random().toString(36).substring(2, 12);
      const formData = this.makeApiParamsFromFormData();
      this.$data.trialCount = 0;
      await this.keepGeneratingContent(formData);
    },
    async keepGeneratingContent(formData){
      const response = await generatePrContentApi(formData, this.$store);
      //set max trial count
      if(response.status != "finished" && formData.request_id == this.$data.currentRequestId){
        if(this.$data.trialCount < 150){
          this.$data.trialCount++;
          setTimeout(() => {
            this.keepGeneratingContent(formData);
          }, 1000);
        }
      }
      this.$emit('generating', response);
    },
    isValidateForm() {
      //validation & alert for each field
      if(!this.companyName){
        alert("회사명을 입력해주세요.");
        return false;
      }
      if(!this.leaderName){
        alert("대표자명을 입력해주세요.");
        return false;
      }
      if(!this.pressReleaseSubject){
        alert("보도자료 주제를 선택해주세요.");
        return false;
      }
      if(!this.news){
        alert("보도내용을 입력해주세요.");
        return false;
      }
      if(this.imageForPress == null && this.attachment_url1==null){
        alert("대표 이미지를 업로드 해주세요.");
        return false;
      }
      return true;
    },
    async savePrData(generatedContents, successMsg="저장했습니다."){
      if(this.isValidateForm()){
        const formData = this.makeApiParamsFromFormData();
        formData.title=generatedContents.title
        formData.content=generatedContents.contents
        const res = await savePrContentApi(formData, this.$store);
        this.lastImageForPress = this.imageForPress
        if(res.result === "success"){
          this.$data.prId = res.data.id;
          alert(successMsg);
        }else{
          alert(res.message);
        }
      }
    },
    selectImageForPress: () => {
      document.getElementById("image-for-press").click();
    },
    selectFileForPress: () => {
      document.getElementById("file-for-press").click();
    },
    handleImageForPress(e) {
      const file = e.target.files[0];
      this.imageForPress = file;
    },
    handleFileForPress(e) {
      const file = e.target.files[0];
      this.fileForPress = file;
    },
    hasError(fieldName) {
      if (!this.isDirty) {
        return false;
      }

      return !this[fieldName];
    },
  },
});
</script>

<style scoped lang="scss">
.field-group {
  margin-bottom: 20px;

  .label-wrapper {
    margin-bottom: 10px;

    & > label {
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: -0.02em;
      color: #000000;

      .required {
        font-size: 10px;
      }
    }
  }
}

.field-group-flex {
  display: flex;
}

.press-release-list {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  margin-bottom: 15px;

  > div {
    position: relative;

    input {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;

      &:checked + label {
        background: #333333;
        border-color: #333333;
        color: #fff;
        font-weight: 700;
      }
    }

    label {
      display: block;
      width: 80px;
      height: 40px;
      line-height: 40px;
      background: #ffffff;
      border: 1px solid #e0e0e0;
      border-radius: 4.44255px;
      font-weight: 400;
      font-size: 14.2162px;
      text-align: center;
      letter-spacing: -0.02em;
      cursor: pointer;
    }
  }
}

.field-file {
  margin-bottom: 20px;

  .disclaimer {
    margin-bottom: 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.02em;
    color: #000000;

    span {
      font-size: 10px;
    }
  }

  .field-wrapper {
    display: flex;
    gap: 0 18px;

    .btn {
      flex: 0 0 110px;
    }
  }
}

.btn-group {
  display: flex;
  justify-content: center;
}

.fake-input {
  position: relative;
  width: 100%;
  height: 58px;
  line-height: 58px;
  box-sizing: border-box;
  border: 1px solid #dbdbdb;
  border-radius: 17.7702px;
  padding: 0 38px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  background: #fff;
  color: #9a9a9a;

  input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
  }

  span {
    font-weight: 400;
    font-size: 17.7702px;
    color: #9a9a9a;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 260px;

    &.active {
      color: #000;
    }
  }
}
</style>
