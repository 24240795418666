<template>
  <Layout ref="sideLayout">
    <div class="wrapper">
      <Step1 v-if="step === 1" :change-step="changeStep" :open-page="openMyPage"
             :fetchJournalist="fetchJournalist"
      />
      <Step2 v-if="step === 2"
             :selectedPressReleaseId="selectedPressReleaseId" :open-page="openMyPage"
             :change-step="changeStep" :journalistList="journalists" ref="childComponent" />
    </div>
  </Layout>
</template>

<script>
import Layout from "@/components/common/Layout.vue";
import Step1 from "./Step1.vue";
import Step2 from "./Step2.vue";
import {getUserInfo, listJournalistApi} from "@/api/aipr_api.js";
import {assignUserData} from "@/store/store";

export default {
  name: "PublishPage",
  data() {
    return {
      step: 1,
      selectedPressReleaseId: null,
      journalists: [],
    };
  },
  components: {
    Layout,
    Step1,
    Step2,
  },
  mounted() {
    let msg = this.$route.query.msg;
    this.refershUserData().then( () => {
        if (msg) {
          let price = this.$store.state.user.price
          let planName = this.$store.state.user.planName
          console.log(msg);
          alert(msg);
          if (!msg.includes('실패')) {
            window.gtag('event', 'subscribe', {value: price, currency: "KRW", plan_name: planName, send_to: "G-Z0HD1NJ86X"})
            window.fbq('track', 'Subscribe', {value: price, currency: 'KRW', planName: planName});
          }
          window.location.href = "/publish";
        }
      }
    );
    if (window.innerWidth < 1024) {
      alert('AIPR은 PC화면에 최적화 되어 있습니다.\nPC 화면에서 접속해주세요.');
    }
  },

  methods: {
    openMyPage() {
      // this.$refs.sideLayout.$data.isActiveMyInfoModal=true;
      this.$refs.sideLayout.openMyInfoModal(1);
    },
    async refershUserData(){
      let res = await getUserInfo(this.$store)
      assignUserData(this.$store, res.data);
    },
    changeStep(newStep, selectedPressReleaseId) {
      this.step = newStep;
      this.selectedPressReleaseId = selectedPressReleaseId;
    },
    async fetchJournalist(articleTitle){
      let res = await listJournalistApi(articleTitle, this.$store);
      this.$data.journalists = res;
      if (this.$refs.childComponent) {
        this.$refs.childComponent.propsData = { journalists: res };
      }
    }
  },
};
</script>

<style scoped lang="scss">
.wrapper {
  min-width: 930px;
  padding: 73px 140px;
}
</style>
