<template>
  <input
    :id="id"
    :type="type"
    :value="modelValue"
    @input="updateValue($event.target.value)"
  />
</template>

<script>
export default {
  name: "CustomInput",
  props: {
    id: {
      type: String,
    },
    type: {
      type: String,
      default: "text",
    },
    modelValue: {
      type: [String, Number],
      default: "",
    },
  },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit("update:modelValue", newValue);
      },
    },
  },
  methods: {
    updateValue(newValue) {
      this.$emit("update:modelValue", newValue);
    },
  },
};
</script>

<style scoped>
@media (max-width: 480px) {
  input[type="text"],
  input[type="password"],
  input[type="email"],
  input[type="tel"],
  input[type="url"],
  input[type="file"] {
    width: 100%;
    height: 58px;
    box-sizing: border-box;
    border: 1px solid #dbdbdb;
    border-radius: 17.7702px;
    font-weight: 400;
    font-size: 0.8rem;
    padding: 0 20px;
    appearance: none;
    outline: none;
  }

  input[type="text"]::placeholder,
  input[type="email"]::placeholder,
  input[type="password"]::placeholder,
  input[type="tel"]::placeholder,
  input[type="url"]::placeholder,
  input[type="file"]::placeholder {
    color: #9a9a9a;
  }

  input[type="file"] {
    background: #fff;
  }
}

@media (min-width: 481px) {
  input[type="text"],
  input[type="password"],
  input[type="email"],
  input[type="tel"],
  input[type="url"],
  input[type="file"] {
    width: 100%;
    height: 58px;
    box-sizing: border-box;
    border: 1px solid #dbdbdb;
    border-radius: 17.7702px;
    font-weight: 400;
    font-size: 17.7702px;
    padding: 0 38px;
    appearance: none;
    outline: none;
  }

  input[type="text"]::placeholder,
  input[type="email"]::placeholder,
  input[type="password"]::placeholder,
  input[type="tel"]::placeholder,
  input[type="url"]::placeholder,
  input[type="file"]::placeholder {
    color: #9a9a9a;
  }

  input[type="file"] {
    background: #fff;
  }
}
</style>
