<template>
  <div class="wrapper">
    <form @submit.prevent="handleSubmit">
      <div class="logo">
        <img src="/images/logo.svg" alt="aipr" />
      </div>
      <div class="message">
        비밀번호를 찾고자하는 계정(이메일)을 입력해주세요.
      </div>
      <div class="form-wrapper">
        <div class="field-wrapper">
          <Input placeholder="이메일" v-model="userEmail" />
          <div class="sent-email" v-if="this.sentVerifyEmail">
            메일을 발송하였습니다
          </div>
        </div>
        <Button class="btn-login" msg="비밀번호 재설정" />
      </div>
    </form>

    <div class="utils-menu">
      <div>
        <a href="#" @click="goToLogin">로그인하기</a>
      </div>
    </div>
  </div>
</template>

<script>
import Input from "@/components/common/Input.vue";
import Button from "@/components/common/Button.vue";
import {findPassword} from "@/firebase/firebase_auth_google";

export default {
  name: "FindPassword",
  data() {
    return {
      userEmail: "",
      sentVerifyEmail: false,
    };
  },
  components: {
    Input,
    Button,
  },
  methods: {
    async handleSubmit() {
      await findPassword(this.userEmail);
      this.sentVerifyEmail = true;
      this.$router.push("/login");
    },
    goToLogin() {
      this.$router.push("/login");
    },
  },
};
</script>

<style scoped lang="scss">
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background: #333333;

  .logo {
    margin-bottom: 53px;

    img {
      width: 140px;
      vertical-align: top;
    }
  }

  .sent-email {
    position: absolute;
    bottom: -32px;
    left: 0;
    padding-left: 20px;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.02em;
    color: #ffffff;
    text-align: left;
  }

  .message {
    margin-bottom: 40px;
    text-align: center;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: -0.02em;
    color: #ffffff;
  }

  .form-wrapper {
    width: 430px;
    margin: 0 auto;

    .field-wrapper {
      position: relative;
      margin-bottom: 140px;
    }
  }

  .btn-login {
    width: 430px;
    height: 78px;
    border-radius: 100px;
    font-size: 30px;
    line-height: 36px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #000000;
    background: linear-gradient(90deg, #34f19b 72.28%, #019aff 100%);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  }

  .utils-menu {
    margin-top: 60px;

    > div {
      display: flex;
      justify-content: center;
      font-weight: 400;
      font-size: 16px;
      letter-spacing: -0.02em;

      a {
        color: #24a0ed;
        text-decoration: underline;
      }
    }
  }
}
</style>
