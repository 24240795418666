<template>
  <div class="btn-container">
    <a href="#" @click="clickStart"><img class="header-btn" src="/images/btn-login.png"></a>
    <a href="#" @click="clickStart2"><img class="header-btn" src="/images/btn-start.png"></a>
  </div>
  <div class="wrapper">
    <h3>
      <img
        src="/images/main-img1.png"
        alt="AI PR"
      />
    </h3>
    <br>
    <h2>
      <img
          src="/images/main-img2.png"
          alt="AI PR"
      />
    </h2>
    <br>
    <h3>
      <img
          src="/images/main-img3.png"
          id="main-img3"
          alt="AI PR"
      />
    </h3>
    <br><br>
    <h3>
      <a href="#" @click="clickStart2">
      <img
          src="/images/main-btn1.png"
          alt="AI PR"
      />
      </a>
    </h3>
    <br><br><br>

    <div class="usp">
      <img src="/images/main-img4-a-mo.png" alt="AI PR" id="imagetxt"/>
      <img src="/images/main-img4.png" alt="AI PR" id="imagescreen"/>
    </div>
    <br><br><br>

    <div class="usp">
      <img src="/images/main-img5.png" alt="AI PR" id="imagescreen-pc"/>
      <img src="/images/main-img5-b-mo.png" alt="AI PR" id="imagetxt"/>
      <img src="/images/main-img5.png" alt="AI PR" id="imagescreen-mo"/>

    </div>
    <br><br><br>

    <div class="usp">
      <img src="/images/main-img6-a-mo.png" alt="AI PR" id="imagetxt"/>
      <img src="/images/main-img6.png" alt="AI PR" id="imagescreen"/>
    </div><br>

    <div class="subject">
      <div class="img-mo" style="padding-top: 40px; padding-bottom: 80px;">
        <img
            src="/images/main-img7-mo.png"
            alt="AI PR"
            id="img-mo"
        />
      </div>
      <div class="img-pc">
        <img
            src="/images/main-img7.png"
            alt="AI PR"
            id="img-pc"
        />
      </div>

    </div>

    <div class="dark">
      <h2>
        <img src="/images/main-img8.png" alt="AI PR"/>
      </h2>
      <div class="img-pc">
        <img src="/images/main-img9.png" alt="AI PR"/>
      </div>
      <br><br>


      <h3>
        <a href="#" @click="clickStart2">
        <img
            src="/images/main-btn1.png"
            alt="AI PR"
        />
        </a>
      </h3>
    </div>


    <div class="dark-mo">
      <div class="pricing">
        <img src="/images/main-img9-mo.png" style="width:75%; margin: 20px 0px;">
        <div class="price free" style="background-color:#2DBE7C;">
          <img src="/images/main-img-9-a-mo.png" alt="free plan">
          <button href="#" @click="clickStart2">선택하기</button>
        </div>
        <div class="price basic" style="background-color:#29B1A9;">
          <img src="/images/main-img-9-b-mo.png" alt="basic plan">
          <button href="#" @click="clickStart2">선택하기</button>
        </div>
        <div class="price pro" style="background-color:#2886CB;">
          <img src="/images/main-img-9-c-mo.png" alt="pro plan">
          <button href="#" @click="clickStart2">선택하기</button>

        </div>
        <div class="price enterprise" style="background-color:#029CFE;">
          <img src="/images/main-img-9-d-mo.png" alt="enterprise plan">
          <button href="#" @click="clickStart2">선택하기</button>

        </div>
      </div>

      <h3>
        <a href="#" @click="clickStart2">
          <img
              src="/images/main-btn1.png"
              alt="AI PR"
              style="width:70%"
          />
        </a>
      </h3>

    </div>

    <br><br><br>
    <div class="img-pc">
      <h2>
        <img src="/images/main-img10.png" alt="AI PR" style="width:50%"/>
      </h2>
      <br>
      <h2>
        <img src="/images/main-img11.png" alt="AI PR" style="width:60%;"/>
      </h2>
      <h2>
        <img src="/images/main-img12.png" alt="AI PR" style="width:60%;"/>
      </h2>
      <h2>
        <img src="/images/main-img13.png" alt="AI PR" style="width:60%;"/>
      </h2>
      <h3>
        <a href="#" @click="clickStart2">
        <img src="/images/main-btn2.png" alt="AI PR" style="width:35%"/>
        </a>
      </h3>
    </div>
    <div class="img-mo">
      <img src="/images/main-img13-mo.png">
      <h2>
        <a href="#" @click="clickStart2">
          <img src="/images/main-btn2.png" alt="AI PR" style="width:60%" id="start-pc"/>
        </a>
      </h2>
    </div>



  </div>
  <div class="footer">
    <div class="lp-element lp-pom-image">
        <div class="lp-pom-image-container" style="overflow: hidden;">
          <img src="https://aipr.co.kr/images/img-nav-logo.png" alt="AI PR">
        </div>
    </div>
    <div class="footer_text">
        <p>주식회사 원지랩스 <br class="break-t"><span class="break-m">&nbsp;| &nbsp;</span>대표이사: 곽근봉 &nbsp;| &nbsp;개인정보책임자: 곽근봉 &nbsp;| &nbsp;사업자등록번호: 438-88-02481</p>
        <p>본사: 서울 강남구 영동대로 602 6층 엔034호 | &nbsp;고객지원: 문의게시판 또는 이메일</p>
          <a href="https://terms.1zlabs.com/aipr/" target="blank_" style="text-decoration: none;">이용약관</a> &nbsp; &nbsp;| &nbsp;&nbsp;
          <a href="https://terms.1zlabs.com/aipr-1/" target="blank_" style="font-weight: bold; text-decoration: none;">개인정보처리방침</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "EntryPage`",
};
</script>

<script setup>
import { useRouter } from 'vue-router'
const router = useRouter();
const clickStart = ()=> {
  router.push('/publish')
}
const clickStart2 = ()=> {
  router.push('/register')
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

@media (max-width: 480px) {
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin-top: 3vh;
    margin-bottom: 3vh;
  }
  .dark {
    display: none;
  }
  .dark-mo{
    width: 90%;
  }
  .price {
    border-radius: 20px;
    margin: 24px 0;
    padding: 16px 0;
  }

  .price button{
    background-color: #333333;
    width: 90%;
    font-size: 2.5vh;
    font-weight: bold;
    border-radius: 6px;
    margin-top: 20px;
    margin-bottom: 20px;
    border:none;
    color: #ffffff;
    padding: 20px 10px;
    text-align: center;
    display: inline-block;
  }

  .dark-mo img{
    width: 100%;
  }

  h2 {
    margin-bottom: 10px;
  }

  h1 > img {
    width: 63%;
    vertical-align: middle;
  }

  h2 > img {
    width: 90%;
    vertical-align: middle;
  }

  h3 > img, h3 > a > img {
    width: 63%;
    vertical-align: middle;
  }

  h3 > img, h3 > a > img {
    width: 63%;
    vertical-align: middle;
  }

  #main-img3{
    width: 80%;
    vertical-align: middle;
  }

  .img-pc{
    display:none;
  }

  .img-mo img{
    width: 90%;
  }
  .usp {
    width: 80vw;
  }

  .subject {
    margin-top:20px;
    width: 90vw;
  }

  #imagescreen {
    width: 100%;
    vertical-align: top;
    margin-top: 20px;
  }

  #imagescreen-mo {
    width: 100%;
    vertical-align: top;
  }

  #imagescreen-pc {
    display:none;
  }

  #imagetxt {
    width: 100%;
    vertical-align: middle;
    padding-top: 10px;
  }

  .btn-google img {
    margin-right: 15px;
  }

  .btn-container {
    display: flex;
    justify-content: space-between;
    padding-top: 15px;
    padding-right: 20px;
    padding-bottom: 15px;
    padding-left:20px;
    background: rgba(255,255,255,0);
  }

  .header-btn {
    height: 6vh;
    margin-left: 2vw;
  }

  .footer {
    background: rgba(53, 53, 53, 1);
    padding: 35px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 20vh;
    justify-content: space-between;
  }

  .footer_text{
    color: rgb(201, 201, 201);
    font-weight: 300;
    font-style: normal;
    line-height: 2;
    font-size: 12px;
  }

  a, a:visited {
    color: white;
  }
  .break-m{
    display:none;
  }
}
@media (min-width: 481px) {
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100vw;
    margin-top: 7vh;
    margin-bottom: 7vh;
  }

  .dark {
    background: #333333;
    padding-top: 10vh;
    padding-bottom: 7vh;
  }

  .dark-mo{
    display: none;
  }

  h2 {
    margin-bottom: 30px;
  }

  h1 > img {
    width: 80%;
    vertical-align: top;
  }

  h2 > img {
    width: 50%;
    vertical-align: top;
  }

  h3 > img, h3 > a > img {
    width: 30%;
    vertical-align: top;
  }

  .img-pc img {
    width: 80vw;
  }

  .img-mo{
    display:none;
  }

  .usp {
    margin-top:20px;
    width: 80vw;
  }

  .subject {
    margin-top:20px;
    width: 80vw;
  }

  #imagescreen {
    width: 60%;
    vertical-align: top;
  }

  #imagescreen-pc {
    width: 60%;
    vertical-align: top;
  }

  #imagescreen-mo {
    display:none;
  }

  #imagetxt {
    width: 40%;
    vertical-align: middle;
    padding-top: 10px;
  }

  .btn-google {
    width: 345px;
    height: 54px;
    margin-top: 164px;
    background: #ffffff;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.084), 0px 2px 3px rgba(0, 0, 0, 0.168);
    border-radius: 10px;
    font-size: 20px;
    border-width: 0;
    cursor: pointer;
  }

  .btn-google img {
    margin-right: 15px;
  }

  .btn-container {
    display: flex;
    justify-content: flex-end;
    padding-top: 20px;
    padding-right: 30px;
    padding-bottom: 20px;
    background: #EEEEEE;
  }

  .header-btn {
    height: 7vh;
    margin-left: 2vw;
  }

  .footer {
    background: rgba(53, 53, 53, 1);
    padding: 35px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 20vh;
    justify-content: space-between;
  }

  .footer img{
    width: 100px;
  }

  .footer_text{
    color: rgb(201, 201, 201);
    line-height: 2;
    font-weight: 300;
    font-style: normal;
    font-size: 16px;
  }

  a, a:visited {
    color: white;
  }
  .break-t{
    display:none;
  }
}
</style>
