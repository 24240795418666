import { initializeApp } from "firebase/app";
// Import the functions you need from the SDKs you neednpm install -g firebase-tools
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAWHECtHwM1WT6KgD6eBneHNkDvRPSjevk",
    authDomain: "aipr-49c6e.firebaseapp.com",
    projectId: "aipr-49c6e",
    storageBucket: "aipr-49c6e.appspot.com",
    messagingSenderId: "1038777435947",
    appId: "1:1038777435947:web:a94b2e474bc2a3c170676e",
    measurementId: "G-7CWJCE3FVR"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export {app as firebaseApp}