export const JOB_ROLE_OPTIONS = [
  { value: "대표 / 임원" },
  { value: "PR / 대외협력" },
  { value: "마케팅 / 광고" },
  { value: "기자" },
  { value: "영업" },
  { value: "기획 / 사업개발" },
  { value: "엔지니어링 / 개발" },
  { value: "디자인" },
  { value: "인사 / 교육" },
  { value: "재무 / 경영지원" },
  { value: "기타" },
];
