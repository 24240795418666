<template>
  <router-view></router-view>
</template>

<script setup>
  import {onMounted, ref} from 'vue' // used for conditional rendering
  import { getAuth, onAuthStateChanged } from "firebase/auth";
  import { useRouter } from 'vue-router'
  import {useStore} from "vuex";

  const store = useStore();
  const router = useRouter()
  // const isLoggedIn = ref(true)
  const isLoggedIn = ref(false)
  const isLoading = ref(true)
  const auth = getAuth();

  router.beforeEach(async (to) => {
    if (to.path === '/login') {
      if (store.state.user.authToken) {
        return {
          path: '/publish',
        }
      }
    }else if (to.meta.requiresAuth) {
      if (!store.state.user.authToken) {
        return {
          path: '/login',
          query: {
            redirect: to.fullPath,
          },
        }
      }
    }
  })

  onMounted(async ()=>{
    let urlParams = new URLSearchParams(window.location.search);
    if(urlParams.has('key')){
      store.commit('refreshApiKey',urlParams.get('key'))
    }



  })

  onAuthStateChanged(auth, async (user) => {
    if (user) {
      await store.commit('changeGoogleAuthStatus', true)
      isLoading.value = false;
    } else {
      isLoggedIn.value = false // if we do not
      store.commit('changeGoogleAuthStatus', false)
    }
    // isLoading.value = false;
  }, error => {console.log("auth error"+error)}, complete=>{console.log("completed"+complete)});

</script>



<style>
@font-face {
  font-family: 'Pretendard-Regular';
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
* {
  margin: 0;
  padding: 0;
}
#app {
  width: 100%;
  overflow: hidden;
  font-family: 'Pretendard-Regular', sans-serif;
  font-size: 1.2em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background: #f8f9fc;
}
</style>
