import {
    getAuth,
    signInWithRedirect,
    GoogleAuthProvider,
    setPersistence,
    browserSessionPersistence,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    sendEmailVerification,
    sendPasswordResetEmail
} from "firebase/auth";
import {FRONT_HOST} from "@/api/aipr_api";

async function googleSignIn(){
    const provider = new GoogleAuthProvider();
    const auth = getAuth();
    provider.addScope('https://www.googleapis.com/auth/userinfo.email');
    provider.addScope('https://www.googleapis.com/auth/userinfo.profile');
    auth.useDeviceLanguage();
    setPersistence(auth, browserSessionPersistence)
        .then(() => {
            return signInWithRedirect(auth, provider)
        })
        .catch((error) => {
            console.log(error);
        });
}

async function createUserAccount(email, password) {
    const auth = getAuth();
    //try catch for error handling
    try{
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;
        return user;
    }catch (error) {
        const errorCode = error.code;
        const errorMessage = error.message;
        if(errorCode === 'auth/email-already-in-use'){
            alert('이미 가입된 이메일입니다.')
        }else{
            alert(errorMessage);
        }
        console.log(`sign up error code: ${errorCode}, error message: ${errorMessage}`);
        return false
    }
}

async function signInAccount(email, password) {
    const auth = getAuth();
    //try catch for error handling
    try{
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;
        return user;
    }catch (error) {
        const errorCode = error.code;
        const errorMessage = error.message;
        if(errorCode === 'auth/wrong-password'){
            alert('비밀번호가 틀렸습니다.')
        }else{
            alert(errorMessage);
        }
        console.log(`sign up error code: ${errorCode}, error message: ${errorMessage}`);
        return false
    }
}

//logout function
async function signOut() {
    const auth = getAuth();
    auth.signOut().then(() => {
        console.log("sign out");
    }).catch((error) => {
        console.log(error);
    });
}

async function sendSignInLink(user) {
    // const auth = getAuth();
    const actionCodeSettings = {
        // URL you want to redirect back to. The domain (www.example.com) for this
        // URL must be in the authorized domains list in the Firebase Console.
        url: `${FRONT_HOST}/publish`,
        // This must be true.
        handleCodeInApp: true
    };
    await sendEmailVerification(user, actionCodeSettings)
}

async function findPassword(email) {
    const auth = getAuth();
    await sendPasswordResetEmail(auth, email)
        .then(() => {
            alert("비밀번호 재설정 메일이 전송되었습니다.");
        })
        .catch((error) => {
            // const errorCode = error.code;
            const errorMessage = error.message;
            alert(errorMessage);
        });
}


export default googleSignIn;
export { createUserAccount };
export { signInAccount };
export { signOut };
export { sendSignInLink };
export { findPassword };
