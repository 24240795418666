<template>
  <Modal :is-active="isActive">
    <a class="close-modal" @click="closeModal">닫기</a>
    <ul class="tabs">
      <li :class="{ active: activeIndex === 0 }">
        <a href="#" @click.prevent="() => handleClickTab(0)">내정보</a>
      </li>
      <li :class="{ active: activeIndex === 1 }">
        <a href="#" @click.prevent:="() => handleClickTab(1)">구독관리</a>
      </li>
    </ul>
    <div class="modal-contents">
      <PersonalInfo v-if="activeIndex === 0" />
      <SubscribeInfo v-else />
    </div>
  </Modal>
</template>

<script>
import Modal from "@/components/common/Modal.vue";
import PersonalInfo from "./PersonalInfo.vue";
import SubscribeInfo from "./SubscribeInfo/SubscribeInfo.vue";

export default {
  name: "ModalMyInfo",
  components: {
    Modal,
    PersonalInfo,
    SubscribeInfo,
  },
  props: {
    isActive: {
      type: Boolean,
      requried: true,
    },
    closeModal: {
      type: Function,
      requried: true,
    },
  },
  data() {
    return {
      activeIndex: 0,
    };
  },
  methods: {
    handleClickTab(newIndex) {
      this.activeIndex = newIndex;
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .modal-content {
  width: 800px;
  max-width: 800px;
  min-height: 860px;
  max-height: 583px;
  padding: 52px 64px 34px;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 17px;
  box-sizing: border-box;
  overflow: initial;

  .close-modal {
    padding-right: 40px;
    position: absolute;
    top: 10px;
    right: -88px;
    color: #fff;
    cursor: pointer;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 22px;
      height: 22px;
      background: url("../../../../public/images/img-close@2x.png") 0 0
        no-repeat;
      background-size: 22px auto;
    }
  }

  .tabs {
    display: flex;
    gap: 0 48px;
    justify-content: center;
    align-items: flex-end;
    position: relative;
    width: calc(100% + 128px);
    height: 100px;
    padding: 0 64px;
    left: -64px;
    margin-top: -52px;
    box-sizing: border-box;
    background: #f5f5f5;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 17px 17px 0 0;
    border-bottom: 1px solid #dbdbdb;

    li {
      position: relative;
      margin-right: 40px;
      list-style: none;

      &.active {
        &::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 6px;
          background: #000000;
        }

        a {
          font-weight: 700;
        }
      }

      a {
        display: inline-block;
        padding: 14px;
        font-weight: 400;
        font-size: 28px;
        line-height: 34px;
        letter-spacing: -0.02em;
        color: #000000;
        text-decoration: none;
      }
    }
  }

  .modal-contents {
    padding-top: 40px;
  }
}
</style>
