<template>
  <div class="wrapper my-subscribe" v-if="this.status === 'default'">
    <dl class="info">
      <dt>결제수단</dt>
      <dd>
        <div>
          {{ user.cardInfo }}
        </div>
        <Button
          v-if="!isFreeTrials"
          class="md grey"
          msg="카드변경"
          @click="startPaymentProcess('changeCard')"
        />
      </dd>
    </dl>
    <dl class="info">
      <dt v-if="user.subscriptionStatus == 'cancel'">해지예정일</dt>
      <dt v-else-if="user.planType == 'paid'">결제예정일</dt>
      <dt v-else>무료체험 만료예정일</dt>
      <dd>{{ user.expireDate }}</dd>
    </dl>
    <dl class="info">
      <dt>현재 플랜</dt>
      <dd>
        {{ this.user.planName }} {{ this.user.subscriptionStatus === 'cancel' ? '(해지예정)' : '' }}
        <Button
          class="md grey"
          msg="플랜변경"
          @click.prevent="this.status = 'selectPricingPlan'"
        />
      </dd>
      <dd>
        <ul class="plan-detail">
          <li>보도자료 생성 : 무제한</li>
          <li>보도자료 배포 : {{this.user.maxPublishCount}}회 중 {{this.user.maxPublishCount - this.user.publishedCount}}회 가능</li>
        </ul>
      </dd>
    </dl>
    <dl class="info last">
      <dt>결제 예정금액</dt>
      <dd>{{ this.user.price.toLocaleString() }}원</dd>
    </dl>

    <div v-if="!isFreeTrials" class="btn-group-unsubscribe">
      <a
        v-if="user.subscriptionStatus != 'cancel'"
        href="#"
        class="btn-unsubscribe"
        @click.prevent="this.status = 'unsubscribe'"
        >멤버십 해지하기</a>
      <a
          v-if="user.subscriptionStatus == 'cancel'"
          href="#"
          class="btn-unsubscribe"
          @click="restorePlan"

      >멤버십 복구하기</a>
    </div>

    <dl class="notice">
      <dt>이용안내</dt>
      <dd>
        <ul>
          <li>
            등록하신 결제 수단으로 매월 정기 결제일에 멤버십 이용 금액이
            자동으로 결제됩니다.
          </li>
          <li>결제수단 변경은 신용카드 변경을 지원하고 있습니다.</li>
          <li>
            자동결제에 사용되는 결제를 변경하고 싶으면 결제수단 항목에 있는 카드
            변경 버튼을 눌러주세요.
          </li>
          <li>
            구독은 언제든 해지할 수 있으며, 해지해도 결제 만료일까지 사용
            가능합니다.
          </li>
          <li>
            환불은 배포하기 기능을 사용하지 않은 경우 7일 이내에 AiPR 고객센트를
            통해 환불이 가능합니다.
          </li>
          <li>구독 사용중에는 남은 기간에 대한 금액은 환불되지 않습니다.</li>
        </ul>
      </dd>
    </dl>
  </div>

  <div
    class="wrapper select-pricing-plan"
    v-if="this.status === 'selectPricingPlan'"
  >
    <div class="btn-prev-wrapper">
      <a href="#" class="btn-prev" @click.prevent="this.status = 'default'"
        >앞으로가기</a
      >
    </div>
    <div class="logo">
      <img src="/images/logo-dark.svg" alt="" />
    </div>
    <h2>가장 적합한 플랜을 선택하세요!</h2>

    <ul class="pricing-info">
      <li>
        <div>Free</div>
      </li>
      <li>
        <div>Basic</div>
      </li>
      <li>
        <div>Pro</div>
      </li>
      <li>
        <div>Enterprise</div>
      </li>
    </ul>
    <div class="pricing-detail">
      <table cellspacing="0" cellpadding="0">
        <tr>
          <th scope="col">Ai 보도자료 작성</th>
          <td>무제한</td>
          <td>무제한</td>
          <td>무제한</td>
          <td>무제한</td>
        </tr>
        <tr>
          <th scope="col">보도자료 저장</th>
          <td>무제한</td>
          <td>무제한</td>
          <td>무제한</td>
          <td>무제한</td>
        </tr>
        <tr>
          <th scope="col">보도자료 배포</th>
          <td>X</td>
          <td>2회 / 월</td>
          <td>8회 / 월</td>
          <td>30회 / 월</td>
        </tr>
        <tr>
          <th scope="col">Ai 미디어 매칭</th>
          <td>X</td>
          <td>250명 추천</td>
          <td>500명 추천</td>
          <td>500명 추천</td>
        </tr>
        <tr>
          <th scope="col">1회 배포 기자 수</th>
          <td>X</td>
          <td>최대 500명</td>
          <td>최대 1000명</td>
          <td>최대 3000명</td>
        </tr>
        <tr>
          <th scope="col">키워드 대시보드</th>
          <td>X</td>
          <td>O</td>
          <td>O</td>
          <td>O</td>
        </tr>
        <tfoot>
          <tr>
            <td>&nbsp;</td>
            <td>14일 무료</td>
            <td>99,000원<span> / 월</span></td>
            <td>290,000원<span> / 월</span></td>
            <td>
              990,000원<span> / 월</span>
              <div class="disclaimer">(커스텀 기능 등 협의가능)</div>
            </td>
          </tr>
          <tr>
            <td class="cell-btn">&nbsp;</td>
            <td class="cell-btn">
              <Button
                :msg="freeBtnMsg"
                disabled
                @click.prevent="() => handleChangePlen('free')"
              />
            </td>
            <td class="cell-btn">
              <Button
                :msg="basicBtnMsg"
                :disabled="basicBtnMsg == '현재 플랜'"
                @click.prevent="() => startPaymentProcess('Basic')"
              />
            </td>
            <td class="cell-btn">
              <Button
                :msg="proBtnMsg"
                :disabled="proBtnMsg == '현재 플랜'"
                @click.prevent="() => startPaymentProcess('Pro')"
              />
            </td>
            <td class="cell-btn">
              <Button
                :msg="enterpriseBtnMsg"
                :disabled="enterpriseBtnMsg == '현재 플랜'"
                @click.prevent="() => startPaymentProcess('Enterprise')"
              />
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>

  <div
    class="wrapper select-pricing-plan-complete"
    v-if="this.status === 'selectPricingPlanComplete'"
  >
    <div class="logo">
      <img src="/images/logo-dark.svg" alt="" />
    </div>
    <h2>결제완료</h2>
    <p>AiPR {결제한 플랜명} 구독이 시작됩니다.</p>

    <div class="btn-group">
      <Button msg="확인" @click.prevent="this.handleComplete" />
    </div>
  </div>

  <div class="wrapper unsubscribe" v-if="this.status === 'unsubscribe'">
    <div class="btn-prev-wrapper">
      <a href="#" class="btn-prev" @click.prevent="this.status = 'default'"
        >앞으로가기</a
      >
    </div>
    <h2>
      AiPR 구독을 해지하시면<br />
      아래 혜택들을 이용할 수 없습니다.<br />
      그래도 해지하시겠습니까?
    </h2>

    <div class="current-plan-info">
      <div class="title">현재 플랜 : {{this.user.planName}}</div>

      <ul>
        <li>
          <div class="plan-name">Ai 보도자료 작성</div>
          <div class="plan">무제한</div>
        </li>
        <li>
          <div class="plan-name">보도자료 저장</div>
          <div class="plan">무제한</div>
        </li>
        <li>
          <div class="plan-name">보도자료 배포</div>
          <div class="plan">{{this.user.maxPublishCount}}회 / 월</div>
        </li>
        <li>
          <div class="plan-name">Ai 미디어 매칭</div>
          <div class="plan">{{this.user.maxSendCount/this.user.maxPublishCount/2}}명 추천</div>
        </li>
        <li>
          <div class="plan-name">1회 배포 기자 수</div>
          <div class="plan">최대 {{this.user.maxSendCount/this.user.maxPublishCount}}명</div>
        </li>
        <li>
          <div class="plan-name">키워드 대시보드</div>
          <div class="plan">O</div>
        </li>
      </ul>
    </div>

    <div class="notice">
      구독기간이 만료된 후에는<br />
      저장된 기사를 수정/배포하는 기능이 제한될 수 있습니다.
    </div>

    <div class="btn-group">
      <Button
        msg="구독 해지하기"
        class="light"
        @click.prevent="this.handleUnsubscribe"
      />
      <Button
        msg="구독 계속 유지하기"
        @click.prevent="this.status = 'default'"
      />
    </div>
  </div>

  <div
    class="wrapper unsubscribe-complete"
    v-if="this.status === 'unsubscribeComplete'"
  >
    <h2>구독 해지가 완료되었습니다.</h2>
    <p>{{this.user.expireDate}}일까지 모든 기능을 이용하실수 있습니다.</p>
    <Button msg="확인" @click.prevent="this.handleComplete" />
  </div>
</template>

<script>
import Button from "@/components/common/Button.vue";
import {getPaymentInfo, HOST, restorePlan, unsubscribe} from "@/api/aipr_api";
import {useStore} from "vuex";
import {computed} from "vue";

export default {
  name: "SubscribePage",
  components: {
    Button,
  },
  data() {
    return {
      paymentInfo: null,
      status: "default",
      isFreeTrials: false,
      freeBtnMsg: "선택하기",
      basicBtnMsg: "선택하기",
      proBtnMsg: "선택하기",
      enterpriseBtnMsg: "선택하기"
    };
  },
  setup() {
    const store = useStore();
    const user = computed(() => store.getters.user);
    return {
      user
    };
  },
  mounted() {
    this.user = this.$store.state.user;
    this.setButtonMessage(this.user.planName);
    this.isFreeTrials = this.user.planType === "free";
    this.initPayment();
    const jqueryScript = document.createElement('script')
    jqueryScript.src = 'https://ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js'
    jqueryScript.async = true
    jqueryScript.onload = () => {
      const paypleScript = document.createElement('script')
      paypleScript.src = 'https://democpay.payple.kr/js/cpay.payple.1.0.1.js'
      paypleScript.async = true
      document.head.appendChild(paypleScript)
    }
    document.head.appendChild(jqueryScript)
  },
  methods: {
    async restorePlan(){
      let res = await restorePlan(this.$store);
      alert(res.message);
      if(res.result === "success"){
        window.location.reload();
      }
    },
    setButtonMessage(currentPlanName){
      this.freeBtnMsg = "선택 불가";
      if(currentPlanName == "Basic"){
        this.basicBtnMsg = "현재 플랜";
      }else if(currentPlanName == "Pro"){
        this.proBtnMsg = "현재 플랜";
      }else if(currentPlanName == "Enterprise"){
        this.enterpriseBtnMsg = "현재 플랜";
      }else{
        this.freeBtnMsg = "현재 플랜";
      }
    },
    async handleUnsubscribe() {
      let result = await unsubscribe(this.$store);
      alert(result.message);
      if(result.result === "success"){
        this.status = "unsubscribeComplete";
      }
    },
    handleComplete() {
      window.location.reload();
    },
    handleChangePlen(plan) {
      console.log(plan);
      this.status = "selectPricingPlanComplete";
    },
    async initPayment(){
      let res = await getPaymentInfo();
      if(res.result == "success"){
        this.paymentInfo = res.data;
      }
    },
      getPrice(planName) {
        let price = 0;
        let newPrice = 0;
        if(planName == "Basic"){
          price = 99000;
        }else if(planName == "Pro"){
          price = 290000;
        }else if(planName == "Enterprise"){
          price = 990000;
        }else if(planName == "changeCard"){
          price = 0;
        }
        if(this.user.price > 0){
          newPrice = price - this.user.price
          const today = new Date();
          const oneMonthLater = new Date(today.getFullYear(), today.getMonth() + 1, today.getDate());
          const formattedDate = oneMonthLater.toLocaleDateString('ko-KR', { year: 'numeric', month: 'long', day: 'numeric' });
          let alertMsg = `${this.user.planName} 플랜에서 ${planName} 플랜으로 변경 시, 차액인 ${newPrice.toLocaleString()}원이 즉시 추가 결제됩니다. 다음 결제 예정일인 ${formattedDate}부터는 ${planName} 플랜 가격인 ${price.toLocaleString()}원이 적용됩니다.`
          alert(alertMsg);
          price = newPrice;
        }
        if(price < 0){
          price = 0;
        }
        return price;
      },
     async startPaymentProcess(planName) {
      if(this.paymentInfo == null){
        await this.initPayment();
      }
      var obj = new Object();
      obj.PCD_PAY_TYPE = "card";
      if(planName == "changeCard"){
        obj.PCD_PAY_WORK = "AUTH";
      }else{
        obj.PCD_PAY_WORK = "CERT";
      }

      /* 01 : 빌링키결제 */
      obj.PCD_CARD_VER = "01"
      obj.PCD_PAYER_NO = `${this.$store.state.user.id}`;
      obj.PCD_PAYER_NAME = `${this.$store.state.user.name}`;
      obj.PCD_PAYER_HP = `${this.$store.state.user.phoneNumber}`;
      obj.PCD_PAYER_EMAIL = `${this.$store.state.user.email}`;
      obj.PCD_PAY_GOODS = planName;
      let price=this.getPrice(planName);
      obj.PCD_PAY_TOTAL = price;
      obj.PCD_PAY_ISTAX = "Y";
      obj.PCD_PAY_TAXTOTAL = 10;
      /* 파트너 인증시 받은 AuthKey 값 입력  */
      obj.PCD_AUTH_KEY = this.paymentInfo.AuthKey;
      /* 파트너 인증시 받은 return_url 값 입력  */
      obj.PCD_PAY_URL = this.paymentInfo.return_url;
      /* 결과를 콜백 함수로 받고자 하는 경우 함수 설정 추가 */
      //obj.callbackFunction = getResult;  // getResult : 콜백 함수명
      obj.PCD_RST_URL = HOST+'/aipr/payple_callback';
      console.log(obj);
     // eslint-disable-next-line
      PaypleCpayAuthCheck(obj);
    },

  },
};
</script>

<style scoped lang="scss">
.wrapper {
  .btn-prev-wrapper {
    .btn-prev {
      display: inline-block;
      position: relative;
      padding-left: 54px;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: -0.02em;
      text-decoration-line: underline;
      text-decoration: underline;
      color: #000000;

      &::after {
        content: "";
        display: block;
        position: absolute;
        top: 3px;
        left: 0;
        width: 37px;
        height: 9px;
        background: url("../../../../../public/images/ico-prev@2x.png") 0 0
          no-repeat;
        background-size: 37px 9px;
      }
    }
  }

  &.my-subscribe {
    padding-top: 20px;
    dl.info {
      width: 440px;
      position: relative;
      margin: 0 auto 20px;
      padding-bottom: 20px;
      text-align: left;

      &.last {
        padding-bottom: 0;
        margin-bottom: 0;

        &::after {
          display: none;
        }
      }

      &::after {
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        left: -180px;
        width: 800px;
        height: 1px;
        background: #dbdbdb;
      }

      dt {
        margin-bottom: 6px;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: -0.02em;
        color: #000000;
      }

      dd {
        display: flex;
        justify-content: space-between;
        font-weight: 400;
        font-size: 24px;
        line-height: 29px;
        letter-spacing: -0.02em;
        color: #000000;

        .notice-free {
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          letter-spacing: -0.02em;
          color: #ff9696;
        }

        .btn {
          height: 50px;
          margin-top: -10px;
          font-size: 16px;
          background: #e8e8e8;
          color: #333;
        }
      }
    }

    .btn-group-unsubscribe {
      display: flex;
      justify-content: center;
      padding-top: 37px;

      .btn-unsubscribe {
        display: block;
        width: 180px;
        height: 40px;
        line-height: 40px;
        font-family: "Inter";
        font-weight: 400;
        font-size: 12px;
        color: #333333;
        border: 1px solid #000000;
        border-radius: 12.4391px;
        text-decoration: none;
      }
    }

    .notice {
      width: calc(100% + 128px);
      margin-left: -64px;
      margin-top: 37px;
      text-align: left;
      padding: 50px 0 50px 168px;
      background: #f5f5f5;
      border-radius: 0px 0px 17px 17px;
      box-sizing: border-box;

      dt {
        margin-bottom: 20px;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: -0.02em;
        color: #24a0ed;
      }

      dd {
        li {
          margin-bottom: 5px;
          position: relative;
          padding-left: 14px;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          letter-spacing: -0.02em;
          color: #24a0ed;
          list-style: none;

          &::after {
            display: block;
            position: absolute;
            top: 5px;
            left: 0;
            content: "";
            width: 3px;
            height: 3px;
            border-radius: 3px;
            background: #24a0ed;
          }
        }
      }
    }
  }

  &.select-pricing-plan {
    .btn-prev-wrapper {
      margin-bottom: 50px;
      text-align: left;
    }

    h2 {
      margin-bottom: 48px;
      text-align: left;
    }

    .logo {
      text-align: left;

      img {
        width: 90px;
      }
    }
    .pricing-info {
      display: flex;
      padding-left: 180px;
      margin-bottom: 14px;

      li {
        flex: 0 0 123px;
        width: 206px;
        padding-top: 13px;
        position: relative;
        list-style: none;

        &::after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          display: block;
          width: 110px;
          height: 3px;
          background: linear-gradient(90deg, #34f19b 72.28%, #019aff 100%);
        }

        div {
          width: 110px;
          font-weight: 700;
          font-size: 12px;
          line-height: 14px;
          text-align: center;
          letter-spacing: -0.02em;
        }
      }
    }
    .pricing-detail {
      table {
        width: 100%;
        table-layout: fixed;

        th,
        td {
          padding: 12px 0;
          border-top: 1px solid #999999;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          text-align: center;
          letter-spacing: -0.02em;

          color: #000000;
        }

        th {
          width: 180px;
          font-weight: 700;
        }

        td {
          width: 123px;
        }

        tfoot {
          td {
            border-top-width: 0;

            &:not(.cell-btn) {
              height: 62px;
              font-weight: 700;
              padding-top: 23px;
              padding-bottom: 0;
              font-size: 16px;
              line-height: 19px;
              border-top-width: 0;
              background: #f5f5f5;
              border-top: 1px solid #999999;
              border-bottom: 1px solid #999999;
              vertical-align: top;
              box-sizing: border-box;

              > div {
                font-size: 8px;
                font-weight: 400;
              }
            }

            &.cell-btn {
              padding-top: 22px;

              .btn {
                width: 94px;
                height: 46px;
                display: inline-block;
                font-size: 16px;
                padding: 0;
              }
            }
          }
        }
      }
    }
  }

  &.select-pricing-plan-complete {
    .logo {
      padding-top: 80px;
      margin-bottom: 26px;
      img {
        width: 118px;
      }
    }

    h2 {
      margin-bottom: 74px;
      font-weight: 700;
      font-size: 30px;
      line-height: 36px;
      text-align: center;
      letter-spacing: -0.02em;
      color: #000000;
    }

    p {
      margin-bottom: 100px;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      letter-spacing: -0.02em;
      color: #000000;
    }

    .btn-group {
      text-align: center;
      .btn {
        width: 240px;
      }
    }
  }

  &.unsubscribe {
    padding-top: 10px;
    text-align: left;

    .btn-prev-wrapper {
      width: 410px;
      margin: 0 auto;
      margin-bottom: 40px;
    }

    h2 {
      width: 410px;
      margin: 0 auto 20px;
    }

    .current-plan-info {
      width: 410px;
      margin: 0 auto 26px;
      padding: 18px 35px;
      background: #f8f9fc;
      border: 1px solid #999999;
      border-radius: 16px;
      box-sizing: border-box;

      .title {
        margin-bottom: 6px;
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
        letter-spacing: -0.02em;
        color: #000000;
      }

      li {
        display: flex;
        justify-content: space-between;

        .plan-name {
          font-weight: 400;
          font-size: 17.85px;
          line-height: 160.5%;
          letter-spacing: -0.02em;
          color: #333333;
        }
        .plan {
          font-weight: 700;
          font-size: 17.85px;
          line-height: 160.5%;
          letter-spacing: -0.02em;
          color: #333333;
        }
      }
    }

    h2 {
      margin-bottom: 12px;
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      letter-spacing: -0.02em;
      color: #000000;
    }

    .img {
      height: 260px;
      margin-left: -20px;
    }

    .notice {
      width: 800px;
      position: relative;
      left: -64px;
      margin-bottom: 40px;
      padding: 16px 0;
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      text-align: center;
      letter-spacing: -0.02em;
      color: #24a0ed;
      background: #dce8ff;
    }

    .btn-group {
      display: flex;
      justify-content: center;
      gap: 0 16px;

      .btn {
        width: 240px;
      }
    }
  }

  &.unsubscribe-complete {
    padding-top: 160px;

    h2 {
      margin-bottom: 26px;
      font-weight: 700;
      font-size: 30px;
      line-height: 36px;
      text-align: center;
      letter-spacing: -0.02em;
      color: #000000;
    }

    p {
      margin-bottom: 50px;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      letter-spacing: -0.02em;
      color: #000000;
    }

    .btn {
      width: 240px;
    }
  }
}
</style>
