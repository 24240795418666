<template>
  <textarea
    :id="id"
    :type="type"
    :value="modelValue"
    @input="updateValue($event.target.value)"
  />
</template>

<script>
export default {
  name: "CustomTextarea",
  props: {
    id: {
      type: String,
      required: true,
    },
    modelValue: {
      type: [String, Number],
      default: "",
    },
  },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit("update:modelValue", newValue);
      },
    },
  },
  methods: {
    updateValue(newValue) {
      this.$emit("update:modelValue", newValue);
    },
  },
};
</script>

<style scoped>
textarea {
  width: 100%;
  height: 122px;
  box-sizing: border-box;
  border: 1px solid #dbdbdb;
  border-radius: 17.7702px;
  font-weight: 400;
  font-size: 17.7702px;
  line-height: 22px;
  padding: 20px 38px;
  appearance: none;
  outline: none;
}

textarea::placeholder {
  color: #9a9a9a;
}
</style>
