<template>
  <Modal :is-active="isActive">
    <div class="lottie-wrapper">
      <Lottie
        :options="defaultOptions"
        :width="380"
        :height="380"
        v-on:animCreated="handleAnimation"
      />
    </div>
    <h2>
      <img
        srcSet="/images/img-publishing@2x.png 2x"
        src="/images/img-publishing.png"
        alt="신청내역을 바탕으로 보도자료를 배포중입니다."
      />
    </h2>
    <div class="process-count">
      {{ processingStatus.current }}/{{ processingStatus.total }}
    </div>
  </Modal>
</template>

<script>
import { ref, watch, onBeforeUnmount } from "vue";
import Lottie from "vue-lottie";
import Modal from "@/components/common/Modal.vue";
import * as animationData from "../../../assets/lf20_u5hmelhh.json";

export default {
  name: "ModalPublishing",
  components: {
    Lottie,
    Modal,
  },
  props: {
    totalCount:{
      type: Number,
      required: true,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    onPublishDone: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    let intervalId;
    const processingStatus = ref({
      current: 0,
      total: props.totalCount,
    });

    async function fetchProcess() {
      // TODO: API 연동
      // try {
      //   const response = await axiosInstance.get("/reporters?page=1&_limit=10");
      //   table.value.rows = response.data.data;
      //   table.value.totalRecordCount = response.data.totalCount;
      // } catch (error) {
      //   console.error("Error fetching posts:", error);
      // }
      const newCurrent = processingStatus.value.current + 1;
      processingStatus.value.total = props.totalCount;
      processingStatus.value.current =
        newCurrent > processingStatus.value.total
          ? processingStatus.value.total
          : newCurrent;

      if (processingStatus.value.current === processingStatus.value.total) {
        clearPolling();

        if (props.onPublishDone) {
          props.onPublishDone();
        }
      }
    }

    const startPolling = () => {
      fetchProcess();
      intervalId = setInterval(fetchProcess, 50);
    };

    const clearPolling = () => {
      clearInterval(intervalId);
    };

    onBeforeUnmount(clearPolling);

    watch(
      () => props.isActive,
      (newIsActive) => {
        if (newIsActive) {
          startPolling();
        } else {
          clearPolling();
        }
      },
      { immediate: true }
    );

    return { processingStatus };
  },
  data() {
    return {
      defaultOptions: { animationData: animationData },
    };
  },
};
</script>

<style scoped lang="scss">
::v-deep .modal-content {
  width: 835px;
  height: 293px;
  max-width: inherit;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 17px;
  box-sizing: content-box;

  .lottie-wrapper {
    position: relative;
    width: 338px;
    height: 108px;
    margin: 0 auto 10px;

    > div {
      position: absolute;
      top: -136px;
      left: 50%;
      transform: translate3d(-50%, 0, 0);
    }
  }

  h2 {
    margin-bottom: 34px;
    text-align: center;

    img {
      vertical-align: top;
    }
  }

  .process-count {
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #000000;
  }
}
</style>
