import axios from "axios";

const HOST = process.env.NODE_ENV=="development"? "http://localhost:3000":"https://https://api.aipr.co.kr";
const axiosInstance = axios.create({
  baseURL: HOST,
});

axiosInstance.interceptors.request.use(
  (config) => {
    // TODO: 매 요청 시 헤더에 토큰 추가하는 로직
    //
    // const token = localStorage.getItem("token");
    // if (token) {
    //   config.headers.Authorization = `Bearer ${token}`;
    // }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // TODO: 인증에러 핸들링
    // if (error.response && error.response.status === 401) {
    // }
    return Promise.reject(error);
  }
);

export default axiosInstance;
