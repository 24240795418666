<template>
  <Layout>
    <div class="wrapper">
      <div class="header-group">
        <h2>뉴스 분석 대시보드</h2>
        <Select
          style="display: none"
          class="my-press-select"
          :options="myPress"
          placeholder="담당하고 계신 업무를 선택해주세요"
        />
      </div>

      <div class="contents">
        <div class="date-filter-wrapper">
          <div class="date">
            <span>시작날짜</span>
            <input type="date" v-model="startDate">
            <span>~</span>
            <span>종료날짜</span>
            <input type="date" v-model="endDate">
          </div>
        </div>

        <div class="panel-wrapper">
          <div class="col col1">
            <div class="row">
              <ChartPanel :publish-data="publishData" />
            </div>
            <div class="row">
              <PressStatusCard
                :released="released"
                :released-per="releasedPer"
                :read="read"
                :read-per="readPer"
                :published="published"
                :published-per="publishedPer"
              />
            </div>
          </div>
          <div class="col col2">
            <NewsList :news-data="newsData" />
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/components/common/Layout.vue";
import NewsList from "./NewsList.vue";
import ChartPanel from "./ChartPanel.vue";
import Select from "@/components/common/Select.vue";
import PressStatusCard from "./PressStatusCard.vue";
import {getDashboardData} from "@/api/aipr_api";

export default {
  name: "DashboardPage",
  components: {
    Layout,
    NewsList,
    ChartPanel,
    Select,
    PressStatusCard
  },
  data() {
    return {
      keyword: "",
      released:null,
      releasedPer:null,
      read:null,
      readPer:null,
      published:null,
      publishedPer:null,
      startDate: new Date(),
      endDate: new Date(),
      newsData: [],
      chartData: [],
      publishData: [],
      myPress: [
        { value: "보도자료1" },
        { value: "보도자료2" },
        { value: "보도자료3" },
        { value: "보도자료4" },
      ],
    };
  },
  watch: {
    startDate: {
      handler: 'dateChanged',
      immediate: true,
    },
    endDate: {
      handler: 'dateChanged',
      immediate: true,
    },
  },
  mounted() {
    let now = new Date(); // 현재 날짜와 시간을 가져옵니다.
    let weekAgo = new Date(now.getTime() - 90 * 24 * 60 * 60 * 1000); // 현재 날짜에서 7일 전을 계산합니다.
    this.startDate = this.dateFormat(new Date(weekAgo.getFullYear(), weekAgo.getMonth(), weekAgo.getDate())); // 최근 일주일의 시작일을 계산합니다.
    this.endDate = this.dateFormat(now); // 현재 날짜를 종료일로 설정합니다.
  },
  methods: {
    dateChanged(){
      this.fetchDashboardData();
    },
    dateFormat(date) {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
    fetchRepoterRank(page) {
      // TODO: fetch data
      console.log(page);
    },
    fetchMediaRank(page) {
      // TODO: fetch data
      console.log(page);
    },
    makePublishData(){
      this.publishData = []
      const dateMap = new Map();
      let currentDate = new Date(this.startDate);
      let finishDate = new Date(this.endDate);
      while (currentDate <= finishDate) {
        const dateKey = currentDate.toISOString().slice(0, 10);
        dateMap.set(dateKey, 0);
        currentDate.setDate(currentDate.getDate() + 1);
      }

      this.chartData.forEach((data) => {
        const dateKey = data.date;
        if (dateMap.has(dateKey)) {
          dateMap.set(dateKey, data.value);
        }
      });
      this.publishData = Array.from(dateMap, ([date, value]) => ({ date, value }));
      console.log(this.publishData)
    },
    async fetchDashboardData() {
      if(typeof this.startDate !== 'string' || typeof this.endDate !== 'string'){
        return
      }
      const dashboardData = await getDashboardData(this.startDate, this.endDate, this.$store)
      this.released = dashboardData.data.published_count
      this.read = dashboardData.data.open_count
      this.published = dashboardData.data.article_count
      this.newsData = dashboardData.data.articles
      if(this.released == 0){
        this.readPer = 0
        this.releasedPer = 0
      }else{
        this.readPer = ((this.read/this.released)*100).toFixed(1)
        this.releasedPer = 100
      }
      if(this.read == 0){
        this.publishedPer = 0
      }else{
        this.publishedPer = ((this.published/this.read)*100).toFixed(1)
      }
      this.newsData = []
      this.chartData = []
      let lastDate = ""
      let lastDateCount = 0
      dashboardData.data.articles.forEach((article) => {
        let dateStr = article.published_at.split("T")[0]
        if(lastDate != "" && lastDate != dateStr){
          this.chartData.push({
            date: lastDate,
            value: lastDateCount
          })
          lastDateCount = 1
        }else{
          lastDateCount += 1
        }
        lastDate = dateStr
        this.newsData.push({
          comapnyName: article.keyword,
          name: article.press_name,
          title: article.title,
          date: article.published_at.split("T")[0],
          link: article.url,
        });
      });
      if(lastDate != ""){
        this.chartData.push({
          date: lastDate,
          value: lastDateCount
        })
      }
      this.chartData.reverse();
      this.makePublishData();
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.wrapper {
  min-width: 930px;
  padding: 73px 140px;

  .header-group {
    display: flex;
    align-items: center;
    margin-bottom: 73px;

    h2 {
      margin-right: 40px;
      font-weight: 900;
      font-size: 28px;
      line-height: 33px;
      color: #333333;
    }

    .my-press-select {
      width: 440px;
    }
  }

  .date-filter-wrapper {
    display: flex;
    align-items: center;

    width: 80%;
    height: 40px;
    padding-left: 16px;
    margin-bottom: 20px;
    box-sizing: border-box;
    background: #ffffff;

    a.btn-calendar {
      display: block;
      width: 20px;
      height: 20px;
      margin-right: 40px;
      background: url("../../../../public/images/ico-calendar@2x.png") center
        no-repeat;
      background-size: 20px auto;
    }

    .date {
      width: 80%;
      display:flex;
      align-items: center;
      justify-content: space-around;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #24a0ed;
    }
  }

  .panel-wrapper {
    display: flex;
    gap: 20px;
    .col {
      &.col1 {
        flex: 0 0 590px;
        width: 590px;
        padding: 20px 36px;
        background: #ffffff;
        border: 1px solid #dbdbdb;
        border-radius: 8.8851px;
        box-sizing: border-box;
      }
    }
  }
}
</style>
