<template>
  <div class="chart-panel-wrapper">
    <Line :data="chartData" :options="chartOption" />
  </div>
</template>

<script>
import { Line } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineController,
  LineElement,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineController,
  LineElement
);

export default {
  name: "ChartPanel",
  components: {
    Line,
  },
  props: {
    publishData: {
      type: Object,
      required: false,
    },
  },
  mounted() {
    this.updateChart();
  },
  data() {
    return {
      chartData: {
        labels: [],
        datasets: [
          {
            label: "게재 수",
            data: [],
            borderColor: "#4097e2",
            backgroundColor: "#4097e2",
            fill: true,
            tension: 0.3,
          },
        ],
      },
      chartOption: {
        responsive: true,
        plugins: {
          legend: {
            labels: {
              color: "#000",
              font: {
                size: 12,
                family: "Arial",
              },
              padding: 10,
            },
          },
          pointStyle: "circle", // Customize the shape of the label item
          boxWidth: 14,
        },
        interaction: {
          mode: "nearest",
          axis: "x",
          intersect: false,
        },
      },
    };
  },
  watch: {
    publishData() {
      this.updateChart();
    },
  },
  methods: {
    updateChart() {
      if(this.publishData === undefined) return;
      const labels = this.publishData.map((obj) => obj.date);
      const values = this.publishData.map((obj) => obj.value);
      this.chartData = {
        labels: labels,
            datasets: [
          {
            label: "게재 수",
            data: values,
            borderColor: "#4097e2",
            backgroundColor: "#4097e2",
            fill: true,
            tension: 0.3,
          },
        ],
      }
    },
  },
};
</script>

<style scoped lang="scss">
.chart-panel-wrapper {
  margin-bottom: 46px;
  box-sizing: border-box;
}
</style>
