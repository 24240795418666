<template>
  <Modal :is-active="isActive" :close-modal="closeModal">
    <h2>{{ title }}</h2>
    <div v-if="mainImgUrl" style="display: flex; justify-content: center; align-items: center;">
      <img v-if="mainImgUrl" class="pr-main-img" :src="mainImgUrl" />
    </div>
    <article class="contents" v-html="formattedText" />
    <div class="terms-wrapper">
      <div class="notice">
        본 기사에 대한 부정확한 사실 또는 허위사실 유포로 인한 책임은 작성자에게
        있습니다.<br />
        타사 비방내용 또는 부적절한 표현 포함 등 위배되는 내용이 있을 경우
        배포가 거절될 수 있습니다.<br />
        배포된 보도자료는 각 언론사의 편집 방향에 따라 내용이 수정되거나 게재가 허용되지 않을 수 있습니다.<br />
        <div class="form-wrapper">
          <Checkbox
            id="agree-publish-article"
            :checked="checkedPublish"
            :label="'위 내용에 동의합니다'"
            @change="handleCheckbox"
          />
        </div>
        <Button
          msg="배포하기"
          :disabled="!checkedPublish"
          @click="onClickPublish"
        />
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from "@/components/common/Modal.vue";
import Button from "@/components/common/Button.vue";
import Checkbox from "@/components/common/Checkbox.vue";

export default {
  name: "ModalArticle",
  components: {
    Modal,
    Button,
    Checkbox,
  },
  data() {
    return {
      checkedPublish: false,
    };
  },
  props: {
    isActive: {
      type: Boolean,
      required: false,
    },
    mainImgUrl: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: true,
    },
    contents: {
      type: String,
      required: true,
    },
    closeModal: {
      type: Function,
      required: true,
    },
    onClickPublish: {
      type: Function,
      required: true,
    },
  },
  computed: {
    formattedText() {
      if(!this.contents) return "";
      return this.contents.replace(/\n/g, '<br>')
    }
  },
  methods: {
    handleCheckbox(e) {
      this.checkedPublish = e.target.checked;
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .modal-content {
  width: 835px;
  max-width: 835px;
  max-height: inherit;
  padding: 0;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 17px;

  .pr-main-img{
    width: 85%;
    padding-top: 20px;
    margin-bottom: 20px;
  }

  h2 {
    padding: 32px;
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    letter-spacing: -0.02em;
    color: #000000;
    text-align: center;
    border-bottom: 1px solid #ccc7c7;
  }

  .contents {
    height: 510px;
    overflow: auto;
    padding: 18px 68px 34px;
    font-weight: 400;
    font-size: 16px;
    line-height: 156.34%;
    letter-spacing: -0.02em;
    color: #000000;
    box-sizing: border-box;

    > p {
      margin-bottom: 16px;
    }
  }

  .terms-wrapper {
    padding: 16px 0 24px;
    border-top: 1px solid #ccc7c7;

    .notice {
      margin-bottom: 12px;
      font-weight: 700;
      font-size: 12px;
      line-height: 156.34%;
      text-align: center;
      letter-spacing: -0.02em;
      color: #24a0ed;
    }

    .form-wrapper {
      display: flex;
      justify-content: center;
      padding-top: 12px;
      margin-bottom: 18px;

      input,
      label {
        vertical-align: middle;
      }

      label {
        margin-left: 8px;
        font-weight: 700;
        font-size: 14px;
        line-height: 156.34%;
        letter-spacing: -0.02em;
        color: #000000;
      }
    }

    .btn {
      width: 344px;
    }
  }
}
</style>
