<template>
  <button class="btn">{{ msg }}</button>
</template>

<script>
export default {
  name: "CustomButton`",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.btn {
  height: 78px;
  padding: 0 20px;
  background: none;
  background: #333;
  border-radius: 14px;
  color: #fff;
  border-width: 0;
  font-size: 17px;
  font-weight: 600;
  cursor: pointer;
}

.btn:hover {
  background: #444;
}

.btn:disabled {
  background-color: rgba(51, 51, 51, 0.6);
}

.btn.light:disabled {
  background-color: rgba(232, 232, 232, 0.6);
}

.btn.md {
  height: 60px;
}

.btn.light {
  background: #e8e8e8;
  color: #333;
}

.btn.full-width {
  width: 100%;
}
</style>
