<template>
  <main>
    <nav>
      <h1>
        <img
          srcSet="/images/img-nav-logo@2x.png 2x"
          src="/images/img-nav-logo.png"
          alt="AI PR"
        />
      </h1>

      <ul class="navigation">
        <li class="create">
          <router-link
            to="/create"
            :class="{ active: $route.path === '/create' }"
          >
            보도자료 만들기
          </router-link>
        </li>
        <li class="publish">
          <router-link
            to="/publish"
            :class="{ active: $route.path === '/publish' }"
          >
            보도자료 배포하기
          </router-link>
        </li>
        <li class="dashboard">
          <router-link
            to="/dashboard"
            :class="{ active: $route.path === '/dashboard' }"
          >
            대시보드
          </router-link>
        </li>
      </ul>

      <div class="user-wrapper">
        <div class="info">
          <div class="avatar">
            <img v-if="!user.avatar" src="/images/img-user-dummy.png" alt="" />
            <img v-else :src="user.avatar" alt="" />
          </div>
          <div class="user-info">
            <div class="user-name">{{ user.companyName }}</div>
            <div class="user-email">{{ user.email }}</div>
            <div class="member-type">{{ user.planName }}</div>
          </div>
          <a
            href="#"
            class="setting"
            @click="() => (this.isActiveMyInfoModal = true)"
          >
            <img
              srcSet="/images/ico-setting@2x.png 2x"
              src="/images/ico-setting.png"
              alt="Setting"
            />
          </a>
        </div>
        <div class="bottom">
          <a href="#" class="btn-contact" @click="clickContact">문의하기</a>
          <a href="#" class="btn-logout" @click="clickLogout">Logout</a>
        </div>
      </div>
    </nav>
    <div id="contents">
      <slot></slot>
    </div>
  </main>

  <ModalMyInfo
    ref="myInfoTab"
    :is-active="isActiveMyInfoModal"
    :close-modal="handleCloseMyInfoModal"
  />
</template>

<script>
import { VueElement, computed } from "vue";
import { useStore } from "vuex";
import ModalMyInfo from "../pages/ModalMyInfo/ModalMyInfo.vue";
import { signOut } from "@/firebase/firebase_auth_google";

export default {
  name: "CustomLayout",
  components: { ModalMyInfo },
  setup() {
    const store = useStore();

    const user = computed(() => store.getters.user);

    return {
      user,
    };
  },
  props: {
    children: VueElement,
  },
  data() {
    return {
      isActiveMyInfoModal: false,
    };
  },
  methods: {
    openMyInfoModal(index) {
      this.isActiveMyInfoModal = true;
      this.$refs.myInfoTab.handleClickTab(index);
    },
    handleCloseMyInfoModal() {
      this.isActiveMyInfoModal = false;
    },
    async clickLogout() {
      await this.$store.commit("changeGoogleAuthStatus", false);
      this.$store.dispatch("setUser", {
        authToken: null
      });

      await signOut();
      await this.$router.push("/");
    },
    async clickContact(){
      const email = 'info@aipr.co.kr';
      const subject = `[AiPR] ${this.$store.state.user.company_name} 문의하기`;
      const body = '';
      const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
      window.location.href = mailtoLink;
    }
  },
};
</script>

<style scoped lang="scss">
main {
  position: relative;
  min-height: 100vh;
  box-sizing: border-box;
  padding-left: 242px;
}

nav {
  width: 269px;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #000;
}

nav h1 {
  width: 118px;
  height: 52px;
  margin: 0 auto;
  padding: 68px 0 40px;
}

nav .navigation {
  position: relative;
}

nav .navigation li {
  height: 52px;
}

nav .navigation li a {
  width: 100%;
  height: 52px;
  line-height: 52px;
  display: block;
  padding: 0 34px 0 60px;
  box-sizing: border-box;
  text-align: left;
  color: #fff;
  text-decoration: none;
  font-weight: 900;
  font-size: 18px;
  background-repeat: no-repeat;
}

nav .navigation li a.active {
  color: #000;
  background-color: #fff;
}

nav .navigation li.create a {
  background-image: url("../../../public/images/ico-nav-create@2x.png");
  background-size: 17px 25px;
  background-position: 34px 12px;
}
nav .navigation li.create a.active {
  background-image: url("../../../public/images/ico-nav-create-active@2x.png");
}
nav .navigation li.publish a {
  background-image: url("../../../public/images/ico-nav-publish@2x.png");
  background-size: 20px 15px;
  background-position: 34px center;
}

nav .navigation li.publish a.active {
  background-image: url("../../../public/images/ico-nav-publish-active@2x.png");
}

nav .navigation li.dashboard a {
  background-image: url("../../../public/images/ico-nav-dashboard@2x.png");
  background-size: 18px 19px;
  background-position: 34px 15px;
}

nav .navigation li.dashboard a.active {
  background-image: url("../../../public/images/ico-nav-dashboard-active@2x.png");
}

nav .user-wrapper {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #232323;
  border-top: 1px solid #494949;
}

nav .user-wrapper .info {
  display: flex;
  align-items: center;
  padding: 17px 23px;
  border-bottom: 1px solid #494949;
}

nav .user-wrapper .user-info {
  font-size: 12px;
  color: #fff;
  text-align: left;
}

nav .user-wrapper .user-info .user-name {
  font-size: 16px;
}

nav .user-wrapper .user-info .user-email {
  margin-bottom: 5px;
  font-size: 10px;
}

nav .user-wrapper .user-info .member-type {
  display: inline-block;
  padding: 2px 10px;
  background: #ffffff;
  border-radius: 8000px;
  font-size: 10px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #000000;
  text-transform: uppercase;
}

nav .user-wrapper .info .avatar {
  width: 29px;
  height: 29px;
  margin-right: 17px;
  border-radius: 8px;
  background-color: #fff;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

nav .user-wrapper .info .setting {
  margin-left: auto;
}

nav .user-wrapper .bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 8vh;
  margin: 20px 0;
  padding: 20px 0;
  text-align: center;
  box-sizing: border-box;

  .btn-logout {
    display: block;
    color: #fff;
    font-size: 12px;
    letter-spacing: -0.02em;
    text-decoration: underline;
  }

  .btn-contact {
    display: block;
    width: 86px;
    height: 28px;
    margin-bottom: 17px;
    background: #ffffff;
    border-radius: 22px;
    font-weight: 700;
    font-size: 12px;
    text-align: center;
    color: #333333;
    text-decoration: none;
    line-height: 28px;
  }
}

#contents {
  text-align: left;
}
</style>
