import { createStore } from "vuex";
import VuexPersistedstate from 'vuex-persistedstate'

export default createStore({
  state: {
    // user: null,
    // FIXME: 테스트를 위한 초기값 설정
    user: {
      id: null,
      name: "홍길동",
      companyName: "원지스랩",
      phoneNumber: "010-1234-5678",
      email: "contact@1zlabs.com",
      jobRole: "엔지니어링 / 개발",
      avatar:
        "https://cdn5.vectorstock.com/i/1000x1000/92/89/hipster-avatar-image-vector-19639289.jpg",
      memberType: "basic",
      // FIXME: API 연동
      isSubscribed: true,
      authToken: null,
      planType: "free",
      planName: "FREE 플랜",
      maxPublishCount: 0,
      publishedCount: 0,
      maxSendCount: 0,
      price: 0,
      expireDate: null,
      cardInfo: ""
    },
  },
  mutations: {
    setUser(state, user) {
      state.user = {
        ...state.user,
        ...user,
      };
    },
    changeGoogleAuthStatus(state, authStatus){
      state.googleAuth = authStatus;
    },
    refreshApiKey(state, key){
      state.apiKey = key;
    }
  },
  actions: {
    setUser({ commit }, user) {
      commit("setUser", user);
    },
  },
  getters: {
    user: (state) => state.user,
    userName: (state) => (state.user ? state.user.name : ""),
    companyName: (state) => (state.user ? state.user.companyName : ""),
    phoneNumber: (state) => (state.user ? state.user.phoneNumber : ""),
    email: (state) => (state.user ? state.user.email : ""),
    jobRole: (state) => (state.user ? state.user.jobRole : ""),
    avatar: (state) => (state.user ? state.user.avatar : ""),
    isSubscribed: (state) => (state.user ? state.user.isSubscribed : ""),
  },
  plugins: [VuexPersistedstate()]
});

export function assignUserData(store, userData) {
  const dateString = userData.expire_date;
  const date = new Date(dateString);
  const koreanDate = date.toLocaleDateString("ko-KR", {
    timeZone: "Asia/Seoul",
  });
  store.dispatch("setUser", {
    id: userData.id,
    name: userData.name,
    email: userData.email,
    companyName: userData.company_name,
    jobRole: userData.role,
    avatar:
        "https://cdn5.vectorstock.com/i/1000x1000/92/89/hipster-avatar-image-vector-19639289.jpg",
    memberType: "basic",
    phoneNumber: userData.phone_number,
    authToken: userData.auth_token,
    planType: userData.plan_type,
    planName: userData.plan_name,
    maxPublishCount: userData.max_publish_count,
    publishedCount: userData.published_count,
    maxSendCount: userData.max_send_count,
    subscriptionStatus: userData.subscription_status,
    price: userData.price,
    expireDate: koreanDate,
    cardInfo: userData.card_info
  });
}
