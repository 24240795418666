<template>
  <div class="custom-select">
    <select
      :id="id"
      :value="selectedValue"
      :class="{ 'placeholder-active': !selectedValue }"
      @change="handleChange"
    >
      <option value="" disabled :selected="!selectedValue">
        {{ placeholder }}
      </option>
      <option
        v-for="option in options"
        :key="option.value"
        :value="option.value"
      >
        {{ option.label || option.value }}
      </option>
    </select>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "CustomSelect",
  props: {
    id: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    defaultValue: {
      type: String,
    },
    placeholder: {
      type: String,
      required: false,
      default: "",
    },
  },
  setup(props, { emit }) {
    const selectedValue = ref(props.value || props.defaultSelectedValue || "");

    function handleChange(event) {
      const selectedOption = props.options.find(
        (option) => option.value === event.target.value
      );
      selectedValue.value = selectedOption.value;
      emit("update:modelValue", selectedOption.value);
    }

    return {
      selectedValue,
      handleChange,
    };
  },
});
</script>

<style scoped>
@media (max-width: 480px) {
  .custom-select {
    position: relative;
    width: 100%;
  }

  select {
    width: 100%;
    padding: 0 20px;
    font-size: 0.8rem;
    height: 58px;
    box-sizing: border-box;
    border: 1px solid #dbdbdb;
    appearance: none;
    border-radius: 17.7702px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M7 10l5 5 5-5z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 0.5rem center;
    background-size: 1.5rem auto;
  }

  select:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(66, 153, 225, 0.5);
    border-color: #4299e1;
  }

  select option[disabled] {
    color: #9a9a9a;
  }

  select.placeholder-active {
    color: #9a9a9a;
  }
}


@media (min-width: 481px) {
  .custom-select {
    position: relative;
    width: 100%;
  }

  select {
    width: 100%;
    padding: 0 38px;
    font-size: 1rem;
    height: 58px;
    box-sizing: border-box;
    border: 1px solid #dbdbdb;
    appearance: none;
    border-radius: 17.7702px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M7 10l5 5 5-5z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 0.5rem center;
    background-size: 1.5rem auto;
  }

  select:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(66, 153, 225, 0.5);
    border-color: #4299e1;
  }

  select option[disabled] {
    color: #9a9a9a;
  }

  select.placeholder-active {
    color: #9a9a9a;
  }
}
</style>
