<template>
  <div class="header-group">
    <h2>보도자료 배포하기</h2>
    <h3>작성해둔 기사를 선택하고 언론배포를 시작하세요.</h3>

    <ul class="notice">
      <li>배포가 완료된 보도자료는 재배포가 불가능합니다.</li>
      <li>본 서비스는 보도자료 검토 및 커버리지 리포트를 제공하지 않습니다.</li>
    </ul>

    <ProgressStatus />
  </div>

  <div class="contents">
    <Tabs :tabs-list="tabList" />
    <div class="card-list">
      <PressCard
        v-for="(card, index) in prContentList"
        :id="card.id"
        :key="index"
        :press-status="card.status"
        :title="card.title"
        :contents="card.contents"
        :created-at="card.createdAt"
        :published-count="card.publishedCount"
        :checked-item-id="checkedItemId"
        :handle-copy="handleClickCopy"
        :handle-modify="handleClickModify"
        :handle-delete="handleClickDelete"
        @click="() => handleClickCard(card.id, card.title)"
      />
    </div>

    <div class="btn-group">
      <Button
        class="btn-matching"
        msg="기자 매칭하기"
        style="width: 250px"
        @click="handleMatching"
      />
    </div>
  </div>

  <Modal class="modal-loading" :is-active="isLoadingModalActive">
    <Lottie
      :options="defaultOptions"
      :width="268"
      :height="180"
      v-on:animCreated="handleAnimation"
    />
    <h2>
      AI가 보도자료 내용을 바탕으로<br />
      미디어를 매칭하고 있습니다.
    </h2>
  </Modal>
</template>

<script>
import Lottie from "vue-lottie";
import Button from "@/components/common/Button.vue";
import Modal from "@/components/common/Modal.vue";
import PressCard from "./PressCard.vue";
import ProgressStatus from "./ProgressStatus.vue";
import Tabs from "./Tabs.vue";
import * as animationData from "../../../assets/112626-connecting.json";
import {listPrContents, removePrContent} from "@/api/aipr_api";

export default {
  name: "PublishStep1",
  components: {
    ProgressStatus,
    Tabs,
    PressCard,
    Button,
    Modal,
    Lottie,
  },
  data() {
    return {
      defaultOptions: { animationData: animationData },
      isLoadingModalActive: false,
      checkedItemId: null,
      checkedItemTitle: "",
      prContentList: [],
      tabList: [
        {
          text: "전체",
          count: 100,
        },
        {
          text: "저장된 보도자료",
          count: 33,
        },
        {
          text: "배포완료",
          count: 67,
        },
      ],
    };
  },
  props: {
    changeStep: {
      type: Function,
    },
    fetchJournalist: {
      type: Function,
    },
    openPage:{
      type: Function,
    }
  },
  mounted() {
    this.listPrContent();
  },
  methods: {
    async listPrContent(){
      const contentList = await listPrContents(this.$store);
      this.prContentList = contentList.map((item) => {
        let title = item.title;
        let contents = item.content;
        if(title == null || title == ""){
          title = `[${item.company_name}] 작성중..`;
        }
        if(contents == null || contents == ""){
          contents = item.summary_input;
        }
        return {
          id: item.id,
          title: title,
          contents: contents,
          status: item.status,
          createdAt: new Date(item.updated_at).toISOString().slice(0, 10),
          publishedCount: 0,
        }
      });
    },
    handleClickCard(id, title) {
      this.checkedItemId = id;
      this.checkedItemTitle = title;
    },
    async handleMatching() {
      let user = this.$store.state.user;

      if (!this.checkedItemId){
        alert("보도자료를 선택해주세요.");
        return;
      }

      if(!user || !user.planType){
        alert("구독 정보를 불러올 수 없습니다. 다시 시도해주세요.");
        this.openPage();window.location.reload();
        return;
      }

      // if(user.planType == "free"){
      //   alert("기자 매칭은 유료회원에게만 제공됩니다.");
      //   this.openPage();
      //   return;
      // }
      // if(user.publishedCount >= user.maxPublishCount){
      //   alert(`${user.planName} 플랜의 보도자료 배포횟수를 초과하였습니다.`);
      //   this.openPage();
      //   return;
      // }

      if(this.prContentList.find(item => item.id == this.checkedItemId).status == "published"){
        alert("이미 배포된 보도자료입니다.");
        return;
      }

      if(this.prContentList.find(item => item.id == this.checkedItemId).status == "review"){
        alert("검수중인 보도자료입니다.\n검수 완료 후 자동으로 배포됩니다.\n배포 후 확인 이메일을 보내드릴게요.");
        return;
      }

      this.isLoadingModalActive = true;
      await this.fetchJournalist(this.checkedItemTitle);
      this.changeStep(2, this.checkedItemId);
    },
    handleClickCopy(id) {
      alert(`복사 ${id}`);
    },
    handleClickModify(id) {
      alert(`수정 ${id}`);
    },
    async handleClickDelete(id) {
      if (confirm("삭제하시겠습니까?")) {
        let response = await removePrContent(id,this.$store)
        await this.listPrContent();
        alert(response);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.header-group {
  position: relative;
  margin-bottom: 40px;

  h2 {
    margin-bottom: 10px;
    font-weight: 700;
    font-size: 28px;
    line-height: 33px;
    color: #000;
  }

  h3 {
    margin-bottom: 27px;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.02em;
  }
}

ul.notice {
  width: 468px;
  padding: 13px 11px 13px 18px;
  border: 1px solid #24a0ed;
  background: #dce8ff;
  border-radius: 5px;

  li {
    position: relative;
    padding-left: 14px;
    list-style: none;
    color: #24a0ed;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.02em;

    &::after {
      display: block;
      position: absolute;
      top: 8px;
      left: 0;
      content: "";
      width: 3px;
      height: 3px;
      border-radius: 3px;
      background: #24a0ed;
    }
  }
}

.card-list {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
}

.btn-group {
  width: calc(100% - 269px);
  padding: 20px 0;
  position: fixed;
  bottom: 0;
  right: 0;
  border-top: 1px solid #ccc7c7;
  background: #f8f9fc;
  text-align: center;
}

::v-deep .modal-content {
  width: 578px;
  height: 348px;
  padding: 32px 0 0;
  box-sizing: border-box;

  h2 {
    margin-top: 28px;
    text-align: center;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #000000;
  }
}
.btn-matching{
  height: 8vh;
  font-size: 1em;
}
.contents{
  margin-bottom: 10vh;
}
</style>
