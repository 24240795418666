<template>
  <div class="header-group">
    <h2>AI 미디어매칭</h2>
    <h3>기사내용을 바탕으로 추천하는 미디어/기자 리스트입니다.</h3>

    <ProgressStatus :active-index="1" />
  </div>

  <div class="contents">
    <div class="ai-result">
      "{{this.modalArticleContents.title}}" 보도자료 관련 분석 결과
      <br />{{this.journalistList[0].press_name}}
      {{this.journalistList[0].name}}님 등 {{this.journalistCategories}} 분야 기자님 {{this.journalistList.length}}명을 추천해드려요.
    </div>

    <div class="board-list-wrapper">
      <h4>선택된 기자 리스트</h4>
      <div class="reporter-status">
        <div class="label">선택 가능한 기자</div>
        <div class="count">
          <span>{{this.checkedJournalistSize}}명</span>
          /
          <span>총 {{this.journalistList.length}}명</span>
        </div>
        <div v-if="checkAll" class="select-all"><a href="#" @click="selectAll(true)">전체 선택하기</a></div>
        <div v-else class="select-all"><a href="#" @click="selectAll(false)">전체 해제하기</a></div>
      </div>
      <div class="btn-group">
        <Button class="btn-submit"  @click="submitArticle" :msg="buttonName" />
      </div>
      <table-lite
        ref="tableLiteRef"
        :is-slot-mode="true"
        :is-loading="table.isLoading"
        :columns="table.columns"
        :rows="table.rows"
        :total="table.totalRecordCount"
        :sortable="table.sortable"
        :messages="table.messages"
        @do-search="doSearch"
        @is-finished="table.isLoading = false"
      >
        <template v-slot:article="row">
          <div v-html="this.createLink(row.value.recent_article_title, row.value.recent_article_url)" />
        </template>
        <template v-slot:check="row">
          <input type="checkbox" v-model="row.value.checked" @click="clickCheckBox(row)"  />
        </template>
      </table-lite>
    </div>
  </div>

  <ModalArticle
    v-if="modalArticleContents.isActive"
    :is-active="modalArticleContents.isActive"
    :title="modalArticleContents.title"
    :contents="modalArticleContents.contents"
    :mainImgUrl="modalArticleContents.mainImgUrl"
    :close-modal="closeModalArticle"
    :on-click-publish="onClickPublish"
  />

  <ModalPublishing
    :total-count="checkedJournalistSize"
    :is-active="modalPublishing.isActive"
    :on-publish-done="onPublishDone"
  />

  <ModalPublishDone
    :is-active="modalPublishDone.isActive"
    :user-email="'email@email.com'"
  />
</template>

<script>
import { ref } from "vue";
import TableLite from "vue3-table-lite";
import Button from "@/components/common/Button.vue";
import ProgressStatus from "./ProgressStatus.vue";
import axiosInstance from "@/api/axiosInstance";
import ModalArticle from "./ModalArticle.vue";
import ModalPublishing from "./ModalPublishing.vue";
import ModalPublishDone from "./ModalPublishDone.vue";
import {detailPrContent, sendPr} from "@/api/aipr_api";

export default {
  name: "PublishPage",
  components: {
    TableLite,
    ProgressStatus,
    Button,
    ModalArticle,
    ModalPublishing,
    ModalPublishDone,
  },
  props: {
    selectedPressReleaseId: {
      type: Number,
    },
    changeStep: {
      type: Function,
    },
    journalistList: {
      type: Array,
    },
    openPage:{
      type: Function,
    }
  },
  watch: {
    journalistList: function (newVal) {
      this.table.rows = newVal;
      this.table.totalRecordCount = newVal.length;
    }
  },
  setup() {
    const table = ref({
      keyField: "id",
      isLoading: false,
      columns: [
        {
          label: "선택",
          field: "check",
          width: "60px",
          sortable: false,
          isKey: false,
          type: "checkbox"
        },
        {
          label: "ID",
          field: "id",
          width: "1px",
          sortable: true,
          isKey: true,
        },
        {
          label: "이름",
          field: "name",
          width: "100px",
        },
        {
          label: "소속",
          field: "press_name",
          width: "110px",
        },
        {
          label: "카테고리",
          field: "category",
          width: "100px",
        },
        {
          label: "최근기사",
          field: "article",
        },
      ],
      rows: [
        {
          id: 1,
          name: "김기자",
          press_name: "미디어1",
          category: "분야1",
          recent_article_title: "AI 스타트업 1Z LABS, 최초의 AI타로앱 마이타로 출시",
          recent_article_url: "https://www.naver.com",
        }
      ],
      messages: {
        pagingInfo: `{2}개 결과 중 {0}-{1}`,
        pageSizeChangeLabel: "Display: ",
      },
      totalRecordCount: 0,
      sortable: {
        order: "id",
        sort: "asc",
      },
    });
    return { table };
  },
  mounted() {
    this.fetchJournalistList();
    this.setModalArticleContent();
  },
  data() {
    return {
      journalistCategories: "",
      checkAll: true,
      checkedJournalistSize:0,
      media: "",
      category: "",
      checkedRow: [],
      isLoadingModalActive: false,
      categoryOption: [
        { value: "분야1" },
        { value: "분야2" },
        { value: "분야3" },
      ],
      modalArticleContents: {
        isActive: false,
        title: null,
        contents: null,
      },
      modalPublishing: {
        isActive: false,
      },
      modalPublishDone: {
        isActive: false,
      }
    };
  },
  computed: {
    buttonName() {
      return `최종 리뷰하기`;
    }
  },
  methods: {
    fetchJournalistList() {
      this.table.rows = this.journalistList;
      this.updateCheckedRows();

      const uniqueCategories = new Set();
      this.journalistList.forEach(journalist => {
        uniqueCategories.add(journalist.category);
      });
      const uniqueCategoryList = Array.from(uniqueCategories);
      this.journalistCategories = uniqueCategoryList.join(", ");
    },
    async setModalArticleContent(){
      const res = await detailPrContent(this.selectedPressReleaseId, this.$store)
      this.modalArticleContents.title = res.title
      this.modalArticleContents.contents = res.content
      this.modalArticleContents.mainImgUrl = res.attachment_url1
    },
    doSearch(offset, limit, order, sort) {
      this.table.isLoading = true;
      const page = Math.floor(offset / limit) + 1;
      let url = "/reporters?page=" + page + "&_limit=" + limit;
      axiosInstance.get(url).then((response) => {
        this.table.rows = response.data.data;
        this.table.sorthis.table.order = order;
        this.table.sortable.sort = sort;
      });
      // End use axios to get data from Server
    },
    clickCheckBox(row){
      row.value.checked = !row.value.checked;
      this.updateCheckedRows();
    },
    selectAll(checked){
      this.checkAll = !checked;
      this.table.rows.forEach((item) => {
        item.checked = checked;
      });
      this.updateCheckedRows();
    },
    updateCheckedRows() {
      let checkedRowIds = this.table.rows.filter(item => item.checked).map(item => item.id)
      this.checkedRow = checkedRowIds
      this.checkedJournalistSize = checkedRowIds.length;
    },
    submitArticle() {
      // this.fetchArticle(this.checkedRow);
      this.fetchArticle()
    },
    createLink(text, link) {
      return `<a href="${link}" target="_blank" title="${text}">${text}</a>`;
    },
    closeModalArticle() {
      this.modalArticleContents.isActive = false;
    },
    closeModalPublishing() {
      this.modalPublishing = {
        isActive: false,
      };
    },
    async fetchArticle() {
      this.modalArticleContents.isActive = true;
    },
    async onClickPublish() {
      if(this.$store.state.user.planType == "free"){
        alert("기사 배포는 유료회원에게만 제공됩니다.");
        this.openPage();
        return;
      }
      if(this.$store.state.user.publishedCount >= this.$store.state.user.maxPublishCount){
        alert(`${this.$store.state.user.planName} 플랜의 보도자료 배포횟수를 초과하였습니다.`);
        this.openPage();
        return;
      }
      this.closeModalArticle();
      let journalistIds = this.checkedRow
      await sendPr(this.selectedPressReleaseId, journalistIds, this.$store)
      this.modalPublishing.isActive = true;
    },
    onPublishDone() {
      this.closeModalPublishing();

      this.modalPublishDone = {
        isActive: true,
      };
    },
  },
};
</script>

<style scoped lang="scss">
.header-group {
  position: relative;
  margin-bottom: 40px;

  h2 {
    margin-bottom: 10px;
    font-weight: 700;
    font-size: 28px;
    line-height: 33px;
    color: #000;
  }

  h3 {
    margin-bottom: 27px;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.02em;
  }
}

h4 {
  margin-bottom: 10px;
  font-style: normal;
  font-weight: 800;
  font-size: 17.7702px;
  line-height: 24px;
  color: #000000;
}

.ai-result {
  margin-bottom: 20px;
  padding: 21px 25px;
  border-top: 1px solid #ccc7c7;
  font-weight: 800;
  font-size: 18px;
  line-height: 24px;
  background: #fff;
  color: #000000;
}

.form-wrapper {
  margin-bottom: 32px;

  .field-group {
    width: 660px;
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.reporter-status {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 536px;
  padding: 10px 28px;
  margin-bottom: 24px;
  background: #dce8ff;
  border: 1px solid #24a0ed;
  border-radius: 5px;

  .label {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #24a0ed;
  }

  .count {
    font-weight: 400;
    font-size: 18px;
    line-height: 33px;
    color: #24a0ed;
  }

  .form {
    margin-left: auto;
  }

  .select-all{
    font-size: 15px;
  }
}

::v-deep .vtl-card {
  background: none;

  .vtl-loading-mask {
    background-color: rgba(0, 0, 0, 0.3);
  }

  .vtl-row {
    background: #fff;
  }
  .vtl-table {
    margin-bottom: 0;
  }

  .vtl-thead-th {
    background: #fff;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #343a40;
    border-width: 0;
    border-bottom: 2px solid #eaeaea;

    &:nth-child(2) {
      display: none;
    }

    &:nth-child(4),
    &:nth-child(5) {
      text-align: center;
    }
  }

  .vtl-tbody-td {
    border-left-width: 0;
    border-right-width: 0;

    border-bottom-color: #eaeaea;
    color: #6c757d;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;

    &:nth-child(2) {
      display: none;
    }

    a {
      color: #24a0ed;
      font-size: 12px;
    }
  }

  .vtl-paging {
    display: none;
    align-items: center;
    padding: 0 10px;
    margin-top: 10px;
    border-top: 2px solid #dfdfdf;

    > .vtl-paging-info {
      flex: 0 0 auto;
      font-style: normal;
      font-weight: 400;
      font-size: 7.78814px;
      line-height: 12px;
      color: #6c757d;
    }

    .vtl-paging-change-div {
      flex: 0 0 auto;
      margin-left: auto;

      .vtl-paging-count-label {
        margin-left: auto;
        margin-top: 10px;
        font-size: 8px;
        line-height: 12px;
        color: #6c757d;
      }
    }

    .vtl-paging-page-dropdown {
      display: none;
    }

    .vtl-paging-pagination-div {
      flex: 0 0 auto;
      padding: 8px 0;
      margin-left: 18px;

      .vtl-paging-pagination-ul {
        gap: 6px;
      }

      .vtl-paging-pagination-page-li {
        width: 18px;
        height: 18px;

        &.vtl-paging-pagination-page-li-first,
        &.vtl-paging-pagination-page-li-last {
          display: none;
        }

        &.vtl-paging-pagination-page-li-prev,
        &.vtl-paging-pagination-page-li-next {
          > a {
            position: relative;
            background-color: #dfdfdf;

            &::after {
              content: "";
              display: block;
              width: 6px;
              height: 6px;
              position: absolute;
              top: 0;
              left: 0;
              border-bottom: 1px solid #343a40;
              border-left: 1px solid #343a40;
            }
          }

          span {
            display: none;
          }
        }

        &.vtl-paging-pagination-page-li-prev {
          > a {
            &::after {
              transform: rotate(45deg);
              top: 6px;
              left: 7px;
            }
          }
        }

        &.vtl-paging-pagination-page-li-next {
          > a {
            &::after {
              transform: rotate(-135deg);
              top: 6px;
              left: 4px;
            }
          }
        }

        .vtl-paging-pagination-page-link {
          width: 18px;
          height: 18px;
          padding: 0;
          line-height: 18px;
          border-width: 0;
          background: none;
          border-radius: 1.11259px;
        }
      }

      .vtl-paging-pagination-page-li-number {
        &.disabled {
          > .vtl-paging-pagination-page-link {
            background: #333333;
            color: #fff;
          }
        }
        > .vtl-paging-pagination-page-link {
          font-weight: 400;
          font-size: 7.78814px;
          color: #6c757d;
          text-align: center;
        }
      }
    }
  }
}

::v-deep .btn {
  &.btn-submit {
    width: 412px;
  }
}

.btn-group {
  padding-top: 42px;
  margin-bottom: 30px;
  text-align: center;
}

</style>
