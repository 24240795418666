<template>
  <div class="wrapper wrapper-not-subscribe" v-if="!this.isRegisterSubscribe">
    <div class="logo">
      <img src="/images/logo-dark.svg" alt="AI PR" />
    </div>
    <h2>
      월 99,000원으로<br />
      프리미엄 PR 캠페인을<br />
      바로 시작해보세요
    </h2>
    <div class="img">
      <img
        srcSet="/images/img-pricing-plan@2x.png 2x"
        src="/images/img-pricing-plan.png"
        alt="유료 플랜"
      />
    </div>

    <Button msg="구독하고 배포서비스 이용하기" @click="handleSubscribe" />
  </div>

  <div class="wrapper wrapper-subscribed" v-if="this.isRegisterSubscribe">
    <div class="logo">
      <img src="/images/logo-dark.svg" alt="AI PR" />
    </div>
    <h2>
      AiPR 구독이 시작됩니다.<br />
      이제 자유롭게 프리미엄 기능을<br />
      사용해보세요
    </h2>
    <Button msg="확인" @click="handleComplete" />
  </div>
</template>

<script>
import Button from "@/components/common/Button.vue";

export default {
  name: "SubscribeRegister",
  components: {
    Button,
  },
  data() {
    return {
      isRegisterSubscribe: false,
    };
  },
  methods: {
    handleSubscribe() {
      // TODO: 구독 API 연동
      this.isRegisterSubscribe = true;
      this.$store.dispatch("setUser", {
        isSubscribed: true,
      });
    },
    handleComplete() {
      window.location.reload();
    },
  },
};
</script>

<style scoped lang="scss">
.wrapper {
  display: flex;
  flex-direction: column;
  padding-top: 30px;
}

.wrapper-not-subscribe {
  display: flex;
  flex-direction: column;
  padding-top: 30px;

  img {
    vertical-align: top;
  }

  .logo {
    margin-bottom: 12px;
  }

  h2 {
    margin-bottom: 24px;
    font-weight: 700;
    font-size: 36px;
    line-height: 43px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #000000;
  }

  .img {
    height: 295px;
    text-align: center;
  }

  .btn {
    margin-top: 36px;
  }
}

.wrapper-subscribed {
  padding-top: 110px;

  .logo {
    margin-bottom: 40px;
  }

  h2 {
    margin-bottom: 50px;
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #000000;
  }
  .btn {
    width: 240px;
    margin: 0 auto;
  }
}
</style>
