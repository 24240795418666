<template>
  <div class="card-wrapper">
    <div class="radio-wrapper">
      <input
        type="radio"
        name="press-card"
        :id="id"
        :value="id"
        :checked="checkedItemId === id"
      />
    </div>
    <div
      :class="{ active: checkedItemId === id }"
      class="card-contents-wrapper"
    >
      <div class="badge" :style="{ backgroundColor: badgeColor }">
        {{ getBadgeText() }}
      </div>

      <h4 class="card-title">{{ title }}</h4>
      <p class="card-contents">{{ contents }}</p>

      <div class="utils">
        <div class="btn-group">
          <a
            href="#"
            v-if="publishedCount"
            @click.prevent="() => handleCopy(id)"
            >복사</a
          >
          <a
              href="#"
              v-if="pressStatus === 'review' || pressStatus === 'published'"
              class="disabled"
          >수정</a>
          <a
              href="#"
              v-else
              @click.prevent="() => handleModify(id)"
          >수정</a>

          <a href="#" @click.prevent="() => handleDelete(id)">삭제</a>
        </div>

        <div class="info sm" v-if="publishedCount">
          {{ createdAt }}<br />{{ publishedCount }}개 배포완료
        </div>
        <div class="info" v-else>{{ createdAt }} 최종 수정</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PressCard",
  props: {
    id: {
      type: Number,
    },
    pressStatus: {
      type: String,
    },
    title: {
      type: String,
    },
    contents: {
      type: String,
    },
    createdAt: {
      type: String,
    },
    publishedCount: {
      type: Number,
    },
    checkedItemId: {
      type: Number,
    },
    handleCopy: {
      type: Function,
    },
    handleDelete: {
      type: Function,
    },
  },
  data() {
    return {
      badgeColor: "#24a0ed",
      checkedItem: false,
    };
  },
  methods: {
    getBadgeText() {
      switch (this.pressStatus) {
        case "published":
          this.badgeColor="black"
          return "배포완료";
        case "review":
          this.badgeColor="#ed2424"
          return "검수중";
        default:
          return "임시저장";
      }
    },
    handleClickCard() {
      this.checkedItem = true;
    },
    handleModify(){
      this.$router.push('/create/' + this.id);
    }
  },
};
</script>

<style scoped>
.card-contents-wrapper {
  width: 280px;
  height: 460px;
  position: relative;
  background-color: #ffffff;
  border: 1px solid #e2e2e2;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);
  border-radius: 8.8851px;
  cursor: pointer;
  transition: background-color 0.1s;
}

.card-contents-wrapper.active {
  background-color: #f7f9ff;
  border: 1px solid #e2e2e2;
}

.radio-wrapper {
  margin-bottom: 12px;
  text-align: center;
}

.radio-wrapper input {
  width: 20px;
  height: 20px;
  border-width: 2px;
  border-color: #333333;
}

.badge {
  width: 86px;
  height: 28px;
  margin: 26px auto 16px;
  border-radius: 22px;
  font-weight: 700;
  font-size: 12px;
  line-height: 28px;
  text-align: center;
  color: #ffffff;
  background: #24a0ed;
}

h4.card-title {
  padding: 0 26px;
  margin-bottom: 24px;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: -0.02em;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}

p.card-contents {
  padding: 0 26px;
  font-weight: 400;
  font-size: 15px;
  line-height: 150%;
  letter-spacing: -0.02em;
  color: #000000;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 10;
  overflow: hidden;
  text-overflow: ellipsis;
}

.utils {
  display: flex;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 48px;
  padding: 0 26px;
  box-sizing: border-box;
  bottom: 0;
  left: 0;
  border-top: 1px solid #ccc7c7;
}

.utils .btn-group {
  display: flex;
}

.utils .btn-group a {
  position: relative;
  display: block;
  padding-right: 20px;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: #24a0ed;
  text-decoration: none;
}

.utils .btn-group a::after {
  content: "";
  display: block;
  position: absolute;
  top: 2px;
  right: 10px;
  width: 1px;
  height: 13px;
  background-color: #24a0ed;
}

.utils .btn-group a:last-child {
  padding-right: 0;
}

.utils .btn-group a:last-child::after {
  display: none;
}

.utils .btn-group a.disabled {
  color: #ccc;
  pointer-events: none;
  cursor: not-allowed; /* 커서를 비활성화 표시로 변경 */
}

.utils .info {
  margin-left: auto;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: #000000;
  text-align: right;
}

.utils .info.sm {
  font-size: 12px;
  line-height: 14px;
  text-align: right;
  letter-spacing: -0.02em;
}
</style>
