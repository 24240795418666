<template>
  <Layout>
    <div class="wrapper">
      <h2>AI 보도자료만들기</h2>
      <h3>보도자료 작성에 필요한 정보를 입력해주세요.</h3>

      <div class="section-row">
        <div class="section-col">
          <PanelForm :handleSubmit="handleSubmit" />
        </div>

        <div class="section-col">
          <PanelAIPressResult />
        </div>
      </div>
    </div>

    <ModalPricing :is-active="true" />
  </Layout>
</template>

<script>
import Layout from "@/components/common/Layout.vue";
import PanelForm from "./PanelForm.vue";
import PanelAIPressResult from "./PanelAIPressResult.vue";
import ModalPricing from "../ModalPricing/ModalPricing.vue";

export default {
  name: "CreatePRModalPricing`",
  components: {
    Layout,
    PanelForm,
    PanelAIPressResult,
    ModalPricing,
  },
  methods: {
    handleSubmit: (formData) => {
      // TODO: submit
      console.log(formData);
    },
  },
};
</script>

<style scoped>
.wrapper {
  min-width: 1100px;
  padding: 45px 72px;
  box-sizing: border-box;
}

h2 {
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 28px;
  line-height: 33px;
  color: #000;
}

h3 {
  margin-bottom: 32px;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.02em;
}

.section-row {
  display: flex;
  gap: 0 100px;
}

.section-col:nth-child(1) {
  width: 475px;
  flex: 0 0 475px;
}

.section-col:nth-child(2) {
  width: 100%;
}

.field-group {
  margin-bottom: 20px;
}

.field-group .label-wrapper {
  margin-bottom: 10px;
}

.field-group .label-wrapper > label {
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.02em;
  color: #000000;
}

.field-group-flex {
  display: flex;
}

.press-release-list {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  margin-bottom: 15px;
}

.press-release-list > div {
  position: relative;
}

.press-release-list > div input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.press-release-list > div label {
  display: block;
  width: 80px;
  height: 40px;
  line-height: 40px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 4.44255px;
  font-weight: 400;
  font-size: 14.2162px;
  text-align: center;
  letter-spacing: -0.02em;
  cursor: pointer;
}

.press-release-list > div input:checked + label {
  background: #333333;
  border-color: #333333;
  color: #fff;
  font-weight: 700;
}

.field-file {
  margin-bottom: 20px;
}

.field-file .disclaimer {
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.02em;
  color: #000000;
}

.field-file .field-wrapper {
  display: flex;
  gap: 0 18px;
}

.field-file .field-wrapper .btn {
  flex: 0 0 110px;
}

.btn-group {
  display: flex;
  justify-content: center;
}
</style>
