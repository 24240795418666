<template>
  <div class="ranking-wrapper">
    <h4 v-if="type === 'media'">상위 노출 미디어</h4>
    <h4 v-else>상위 노출 기자</h4>
    <div class="contents">
      <div class="table-wrapper">
        <div class="table-header" v-if="type === 'media'">
          <div>순위</div>
          <div>미디어</div>
          <div>기사수</div>
        </div>
        <div class="table-header" v-else>
          <div>순위</div>
          <div>기자</div>
          <div>기사수</div>
        </div>
        <div class="table-body" v-if="type === 'media'">
          <div class="row" v-for="(data, index) in rankingData" :key="index">
            <div>{{ data.rank }}</div>
            <div>{{ data.company }}</div>
            <div>{{ data.articleCount }}</div>
          </div>
        </div>
        <div class="table-body" v-else>
          <div class="row" v-for="(data, index) in rankingData" :key="index">
            <div>{{ data.rank }}</div>
            <div>
              {{ data.repoter }}
              <span>{{ data.company }}</span>
            </div>
            <div>{{ data.articleCount }}</div>
          </div>
        </div>

        <div class="page-wrapper">
          <a
            href="#"
            :class="{ disabled: currentPage === 1 }"
            class="btn-prev"
            @click.prevent="
              () => {
                if (this.currentPage > 1) {
                  const newPage = this.currentPage - 1;
                  this.currentPage = newPage;
                  handleClickPrev(newPage);
                }
              }
            "
          />
          <div>
            {{ currentPage }}
            /
            {{ totalPage }}
          </div>
          <a
            href="#"
            :class="{ disabled: currentPage === totalPage }"
            class="btn-next"
            @click.prevent="
              () => {
                if (this.currentPage !== totalPage) {
                  const newPage = this.currentPage + 1;
                  this.currentPage = newPage;
                  handleClickNext(newPage);
                }
              }
            "
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DashboardRanking",
  props: {
    rankingData: {
      type: Array,
      required: true,
    },
    totalCount: {
      type: Number,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    handleClickNext: {
      type: Function,
    },
    handleClickPrev: {
      type: Function,
    },
  },
  data() {
    return {
      currentPage: 1,
    };
  },
  computed: {
    totalPage() {
      const viewCount = 5;
      return this.totalCount % viewCount === 0
        ? this.totalCount / viewCount
        : Math.floor(this.totalCount / viewCount) + 1;
    },
  },
};
</script>

<style scoped lang="scss">
.ranking-wrapper {
  width: 286px;
  height: 317px;
  padding: 20px 36px 20px 20px;
  background: #ffffff;
  border: 1px solid #dbdbdb;
  border-radius: 8.8851px;
  box-sizing: border-box;

  h4 {
    margin-bottom: 22px;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #333333;
  }

  .table-wrapper {
    .table-header {
      display: flex;

      div {
        width: 100%;
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
        padding-bottom: 11px;
        border-bottom: 1px solid #adadad;

        &:nth-child(1) {
          flex: 0 0 68px;
        }

        &:nth-child(3) {
          flex: 0 0 44px;
        }
      }
    }

    .table-body {
      padding: 17px 0 20px;
      border-bottom: 1px solid #adadad;

      .row {
        display: flex;
        margin-bottom: 16px;

        &:last-child {
          margin-bottom: 0;
        }

        > div {
          width: 100%;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;

          span {
            display: inline-block;
            margin-left: 10px;
            font-size: 8px;
          }

          &:nth-child(1) {
            flex: 0 0 68px;
          }

          &:nth-child(3) {
            flex: 0 0 44px;
          }
        }
      }
    }
  }

  .page-wrapper {
    display: flex;
    justify-content: flex-end;
    padding-top: 10px;

    div {
      padding-top: 2px;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #333333;
    }

    .btn-next,
    .btn-prev {
      position: relative;
      display: block;
      width: 20px;
      height: 20px;

      &::after {
        content: "";
        position: absolute;
        display: block;
        width: 6px;
        height: 6px;
        transform-origin: center;
      }

      &.disabled {
        &::after {
          border-bottom-color: #adadad;
          border-left-color: #adadad;
          border-left-width: 1px;
          border-bottom-width: 1px;
        }
      }
    }

    .btn-prev {
      &::after {
        top: 6px;
        left: 8px;
        border-bottom: 2px solid #000;
        border-left: 2px solid #000;
        transform: rotate(45deg);
      }
    }
    .btn-next {
      &::after {
        top: 6px;
        left: 5px;
        border-bottom: 2px solid #000;
        border-left: 2px solid #000;
        transform: rotate(-135deg);
      }
    }
  }
}
</style>
