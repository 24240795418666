<template>
  <div class="wrapper">
    <div class="field-group field-email">
      <div class="label-wrapper">
        <label for="">계정</label>
      </div>
      <div class="text-contents">{{myInfo.email}}</div>
      <div class="member-type">{{myInfo.planName}}</div>
    </div>
    <div class="field-group">
      <div class="label-wrapper">
        <label for="user-name">담당자명</label>
      </div>
      <Input
        id="user-name"
        v-model="myInfo.userName"
        placeholder="담당자명을 입력해주세요"
      />
    </div>

    <div class="field-group">
      <div class="label-wrapper">
        <label for="company-name">회사명</label>
      </div>
      <Input
        id="company-name"
        placeholder="회사명 또는 브랜드명을 입력해주세요"
        v-model="myInfo.companyName"
      />
    </div>

    <div class="field-group">
      <div class="label-wrapper">
        <label for="user-mobile">담당자 휴대전화</label>
      </div>
      <Input
        type="tel"
        id="user-mobile"
        v-model="myInfo.userMobile"
        placeholder="회사명 또는 브랜드명을 입력해주세요"
      />
    </div>

    <div class="field-group">
      <div class="label-wrapper">
        <label for="email">이메일</label>
      </div>
      <Input
        type="email"
        id="email"
        :readonly="true"
        :value="myInfo.email"
        placeholder="이메일을 입력해주세요"
      />
    </div>

    <div class="field-group" style="display:none;">
      <div class="label-wrapper">
        <label for="job-role">담당하고 계신 업무는 무엇인가요?</label>
      </div>
      <Select
        id="job-role"
        :options="jobRoleOptions"
        :value="myInfo.jobRole"
        placeholder="담당하고 계신 업무를 선택해주세요"
      />
    </div>

    <div class="btn-group">
      <Button msg="저장" class="full-width" @click="saveMyInfo" />
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import Input from "@/components/common/Input.vue";
import Select from "@/components/common/Select.vue";
import Button from "@/components/common/Button.vue";
import { JOB_ROLE_OPTIONS } from "@/constants";
import {useStore} from "vuex";
import {updateUserProfile} from "@/api/aipr_api";
import {assignUserData} from "@/store/store";

export default {
  name: "PersonalInfo",
  components: {
    Input,
    Select,
    Button,
  },
  props: {
    isActive: {
      type: Boolean,
      requried: true,
    },
    closeModal: {
      type: Function,
      requried: true,
    },
  },

  setup() {
    const myInfo = ref({});
    async function fetchUserData() {
      try {
        const store = useStore();
        myInfo.value.userName = store.state.user.name;
        myInfo.value.companyName = store.state.user.companyName;
        myInfo.value.userMobile = store.state.user.phoneNumber;
        myInfo.value.email = store.state.user.email;
        myInfo.value.jobRole = store.state.user.jobRole;
        myInfo.value.planName = store.state.user.planName;
      } catch (error) {
        console.error("Error fetching posts:", error);
      }
    }

    onMounted(fetchUserData);

    return { myInfo };
  },
  data() {
    return {
      jobRoleOptions: JOB_ROLE_OPTIONS,
    };
  },
  methods: {
    async saveMyInfo() {
      const res = await updateUserProfile(this.myInfo.userName, this.myInfo.companyName, this.myInfo.userMobile, this.$store)
      if(res.result === "success"){
        await assignUserData(this.$store, res.data);
      }
      alert(res.message);
    },
  },
};
</script>

<style scoped lang="scss">
.wrapper {
  width: 440px;
  margin: 0 auto;

  .field-email {
    display: flex;
    gap: 0 30px;
    align-items: center;

    .label-wrapper {
      padding-top: 10px;
    }

    .member-type {
      padding: 6px 20px;
      border: 1px solid #000000;
      border-radius: 19999px;
      font-family: "Apple SD Gothic Neo";
      font-weight: 400;
      font-size: 16px;
      text-align: center;
      letter-spacing: -0.02em;
      color: #000000;
      margin-left: auto;
    }
  }

  .field-group {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }

    .label-wrapper {
      margin-bottom: 8px;
      text-align: left;

      label {
        font-weight: 400;
        font-size: 14.2162px;
        line-height: 17px;
        letter-spacing: -0.02em;
        color: #000000;
      }
    }

    .text-contents {
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: -0.02em;
      color: #000000;
    }
  }

  .btn-group {
    margin-top: 130px;
  }
}
</style>
