<template>
  <SubscribeRegister v-if="!this.isSubscribed" />
  <SubscribePage v-else :is-free-trials="this.isFreeTrials" />
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import SubscribeRegister from "./SubscribeRegister.vue";
import SubscribePage from "./SubscribePage.vue";

export default {
  name: "SubscribeInfo",
  components: {
    SubscribeRegister,
    SubscribePage,
  },
  setup() {
    const store = useStore();

    const isSubscribed = computed(() => store.getters.isSubscribed);
    const isFreeTrials = computed(() => store.getters.isFreeTrials);

    return {
      isSubscribed,
      isFreeTrials,
    };
  },
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss"></style>
