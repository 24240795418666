<template>
  <ul class="tabs-list">
    <li
      v-for="(info, index) in tabsList"
      :key="index"
      :class="{ active: activeIndex === index }"
    >
      <a> {{ info.text }} ({{ info.count }}) </a>
    </li>
  </ul>
</template>

<script>
export default {
  name: "PublishTabs",
  data() {
    return {
      activeIndex: 0,
    };
  },
  props: {
    tabsList: {
      type: Array,
    },
  },
  components: {},
};
</script>

<style scoped>
.tabs-list {
  /*display: flex;*/
  display: none;
  width: 100%;
  margin-bottom: 40px;
  border-bottom: 1px solid #ccc7c7;
}

.tabs-list li {
  margin-right: 50px;
  list-style: none;
}

.tabs-list li a {
  display: block;
  padding: 14px 0;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
}

.tabs-list li.active a {
  position: relative;
  font-weight: 700;
}

.tabs-list li.active a::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 6px;
  background: #000;
}
</style>
