<template>
  <div class="press-status-wrapper">
    <ul>
      <li class="card card-released">
        <div class="icon">
          <img
            srcSet="/images/ico-press-status-released@2x.png 2x"
            src="/images/ico-press-status-released.png"
            alt=""
          />
        </div>
        <div class="name">배포</div>
        <div class="status">{{ released }}</div>
        <div class="per">{{ releasedPer }}%</div>
      </li>
      <li class="card card-read">
        <div class="icon">
          <img
            srcSet="/images/ico-press-status-read@2x.png 2x"
            src="/images/ico-press-status-read.png"
            alt=""
          />
        </div>
        <div class="name">열람</div>
        <div class="status">{{ read }}</div>
        <div class="per">{{ readPer }}%</div>
      </li>
      <li class="card card-published">
        <div class="icon">
          <img
            srcSet="/images/ico-press-status-published@2x.png 2x"
            src="/images/ico-press-status-published.png"
            alt=""
          />
        </div>
        <div class="name">게재</div>
        <div class="status">{{ published }}</div>
        <div class="per">{{ publishedPer }}%</div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "PressStatusCard",
  props: {
    released: {
      type: Number,
      required: true,
    },
    releasedPer: {
      type: Number,
      required: true,
    },
    read: {
      type: Number,
      required: true,
    },
    readPer: {
      type: Number,
      required: true,
    },
    published: {
      type: Number,
      required: true,
    },
    publishedPer: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
.press-status-wrapper {
  width: 100%;
  overflow: auto;

  ul {
    display: flex;
    gap: 0 16px;

    .card {
      width: 166px;
      height: 220px;
      border-radius: 8.8851px;
      list-style: none;
      color: #fff;
      text-align: center;

      &.card-released {
        background: #276bdd;
      }
      &.card-read {
        background: #34803d;
      }
      &.card-published {
        color: #333333;
        background: #f1a533;
      }

      .icon {
        padding-top: 24px;
        margin-bottom: 18px;
        height: 24px;
        text-align: center;
      }

      .name {
        margin-bottom: 7px;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
      }

      .status {
        margin-bottom: 8px;
        font-weight: 700;
        font-size: 44px;
        line-height: 53px;
        text-align: center;
      }

      .per {
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
      }
    }
  }
}
</style>
