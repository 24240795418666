<template>
  <Layout>
    <div class="wrapper">
      <h2>AI 보도자료만들기</h2>
      <h3>보도자료 작성에 필요한 정보를 입력해주세요.</h3>

      <div class="section-row">
        <div class="section-col">
          <PanelForm ref="childFrom" @generating="updateDraft" />
        </div>

        <div class="section-col">
          <PanelAIPressResult ref="generatedForm" :contents="draftContents" @clickSave="handleSubmit" @clickPublish="handlePublish"/>
        </div>
      </div>
    </div>
    <div v-if="isMobile" class="system-message">'AIPR은 PC화면에 최적화 되어 있습니다.<br>PC 화면에서 접속해주세요.'</div>
  </Layout>
</template>

<script>
import Layout from "@/components/common/Layout.vue";
import PanelForm from "./PanelForm.vue";
import PanelAIPressResult from "./PanelAIPressResult.vue";
import { detailPrContent, getUserInfo } from "@/api/aipr_api";
import { assignUserData } from "@/store/store";

export default {
  name: "CreatePR",
  data: () => ({
    draftContents: {},
    isMobile: false,
  }),
  props: {
    pressReleaseId: {
      type: Number,
      default: null,
    },
  },
  components: {
    Layout,
    PanelForm,
    PanelAIPressResult,
  },
  mounted() {
    if (this.pressReleaseId) {
      this.fetchSavedPrContents();
    }
    this.refreshUserData();
    this.checkMobile();
    window.addEventListener("resize", this.checkMobile);
    if (this.isMobile) {
      console.log('mobile');
      alert('AIPR은 PC 화면에 최적화되어 있습니다. \nPC 화면에서 접속해주세요.');
    }
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.checkMobile);
  },
  methods: {
    async refreshUserData() {
      let res = await getUserInfo(this.$store);
      assignUserData(this.$store, res.data);
    },
    async fetchSavedPrContents() {
      let savedPrContent = await detailPrContent(
          this.pressReleaseId,
          this.$store
      );
      this.$refs.childFrom.$data.prId = savedPrContent.id;
      this.$refs.childFrom.$data.companyName = savedPrContent.company_name;
      this.$refs.childFrom.$data.leaderName = savedPrContent.ceo_name;
      this.$refs.childFrom.$data.webPage = savedPrContent.homepage_url;
      this.$refs.childFrom.$data.pressReleaseSubject =
          savedPrContent.subject_type;
      this.$refs.childFrom.$data.news = savedPrContent.summary_input;
      this.$refs.childFrom.$data.attachment_url1 =
          savedPrContent.attachment_url1;
      this.$refs.childFrom.$data.attachment_url2 =
          savedPrContent.attachment_url2;
      this.$refs.generatedForm.$data.localTitle = savedPrContent.title;
      this.$refs.generatedForm.$data.localContents = savedPrContent.content;
      if (savedPrContent.title != null && savedPrContent.title != "") {
        this.$refs.childFrom.$data.generatePrCTA = "다시 생성하기";
      }
    },
    handleSubmit(generatedContents) {
      this.$refs.childFrom.savePrData(generatedContents);
    },
    async handlePublish(generatedContents) {
      await this.$refs.childFrom.savePrData(generatedContents, "저장완료!\n기자 매칭하기을 시작할게요");
      this.$router.push(`/publish`);
    },
    updateDraft(draft) {
      this.draftContents = draft;
    },
    checkMobile() {
      this.isMobile = window.innerWidth <= 720;
    },
  },
};
</script>

<style scoped>
.wrapper {
  min-width: 1100px;
  padding: 45px 72px;
  box-sizing: border-box;
}

h2 {
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 28px;
  line-height: 33px;
  color: #000;
}

h3 {
  margin-bottom: 32px;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.02em;
}

.section-row {
  display: flex;
  gap: 0 100px;
}

.section-col:nth-child(1) {
  width: 475px;
  flex: 0 0 475px;
}

.section-col:nth-child(2) {
  width: 100%;
}

.field-group {
  margin-bottom: 20px;
}

.field-group .label-wrapper {
  margin-bottom: 10px;
}

.field-group .label-wrapper > label {
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.02em;
  color: #000000;
}

.field-group-flex {
  display: flex;
}

.press-release-list {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  margin-bottom: 15px;
}

.press-release-list > div {
  position: relative;
}

.press-release-list > div input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.press-release-list > div label {
  display: block;
  width: 80px;
  height: 40px;
  line-height: 40px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 4.44255px;
  font-weight: 400;
  font-size: 14.2162px;
  text-align: center;
  letter-spacing: -0.02em;
  cursor: pointer;
}

.press-release-list > div input:checked + label {
  background: #333333;
  border-color: #333333;
  color: #fff;
  font-weight: 700;
}

.field-file {
  margin-bottom: 20px;
}

.field-file .disclaimer {
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.02em;
  color: #000000;
}

.field-file .field-wrapper {
  display: flex;
  gap: 0 18px;
}

.field-file .field-wrapper .btn {
  flex: 0 0 110px;
}

.btn-group {
  display: flex;
  justify-content: center;
}

.system-message {
  margin-top: 20px;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: red;
  text-align: center;
}
</style>