import axios from "axios";

export const HOST = process.env.NODE_ENV=="development"? "http://localhost:3000": "https://api.aipr.co.kr";
export const FRONT_HOST = process.env.NODE_ENV=="development"? "http://localhost:8080":"https://aipr.co.kr";

export async function generatePrContentApi(formData, store) {
    return await defaultApiCall('/aipr/generate_pr_content', formData, store);
}

export async function savePrContentApi(formData, store) {
    if(store && store.getters.user){
        formData.auth_token = store.getters.user.authToken;
        formData.aipr_user_id = store.getters.user.id;
    }
    let res = await axios.post(HOST+'/aipr/save_pr_content', formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
    return res.data;
}

//list journalist
export async function listJournalistApi(articleTitle, store) {
    const formData = {content: articleTitle}
    const response = await defaultApiCall('/aipr/recommend_journalists', formData, store);
    return response.journalists;
}

export async function listPrContents(store) {
    const response = await defaultApiCall('/aipr/press_releases/list', {}, store);
    return response.data;
}

export async function removePrContent(pressReleaseId, store) {
    const response = await defaultApiCall('/aipr/press_releases/remove',
        {aipr_press_release_id: pressReleaseId}, store);
    return response.message;
}

export async function detailPrContent(pressReleaseId, store) {
    const response = await defaultApiCall('/aipr/press_releases/details',
        {aipr_press_release_id: pressReleaseId}, store);
    return response.data;
}

export async function updateUserProfile(name, companyName, phoneNumber, store) {
    const response = await defaultApiCall('/aipr/users/update_user',
        {name: name, company_name: companyName, phone_number:phoneNumber}, store);
    return response;
}

export async function sendAuthEmail(email) {
    const response = await defaultApiCall('/aipr/send_email_auth',
        {email: email});
    return response;
}

export async function checkAuthEmail(email, authNumber) {
    const response = await defaultApiCall('/aipr/check_email_auth',
        {email: email, auth_number: authNumber});
    return response;
}

//register user
export async function registerUserApi(name, companyName, email, phoneNumber, jobTitle, role, authToken) {
    const userData = {}
    userData.name = name
    userData.company_name = companyName
    userData.email = email
    userData.phone_number = phoneNumber
    userData.job_title = jobTitle
    userData.role = role
    userData.auth_token = authToken
    const response =  await defaultApiCall('/aipr/users/register', userData);
    return response;
}

export async function signInUser(email, authToken) {
    const formData = {email: email, auth_token:authToken}
    const response = await defaultApiCall('/aipr/users/sign_in', formData);
    return response;
}

export async function sendPr(pressReleaseId, journalistIds, store) {
    const response = await defaultApiCall('/aipr/press_releases/send_pr',
        {aipr_press_release_id: pressReleaseId, journalist_ids: journalistIds}, store);
    return response;
}

export async function getDashboardData(startFrom, endTo, store) {
    const response = await defaultApiCall('/aipr/dashboard_data',
        {start_from: startFrom, end_to: endTo}, store);
    return response;
}

export async function unsubscribe(store) {
    const response = await defaultApiCall('/aipr/unsubscribe',{}, store);
    return response;
}

export async function restorePlan(store) {
    const response = await defaultApiCall('/aipr/restore_subscription',{}, store);
    return response;
}

export async function getPaymentInfo() {
    const response = await defaultApiCall('/aipr/payment_info',{});
    return response;
}

export async function getUserInfo(store) {
    const response = await defaultApiCall('/aipr/users/user_info',
        {}, store);
    return response;
}

async function defaultApiCall(url, formData, store=null) {
    if(store && store.getters.user){
        formData.auth_token = store.getters.user.authToken;
        formData.aipr_user_id = store.getters.user.id;
    }
    const response = await fetch(HOST+url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
    })
    if (!response.ok) {
        throw new Error('API 호출에 실패했습니다.')
    }
    return response.json()
}

