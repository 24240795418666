<template>
  <div class="modal" :class="{ 'is-active': isActive }" v-if="isActive">
    <div class="modal-background" @click="close"></div>
    <div class="modal-content">
      <slot></slot>
    </div>
    <button
      class="modal-close is-large"
      aria-label="close"
      @click="close"
    ></button>
  </div>
</template>

<script>
export default {
  name: "CustomModal",
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    closeModal: {
      type: Function,
    },
  },
  methods: {
    close() {
      this.$emit("update:isActive", false);
      if (this.closeModal) {
        this.closeModal();
      }
    },
  },
};
</script>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  z-index: 9999;
  overflow: auto;
}

.modal.is-active {
  display: flex;
}

.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
}

.modal-content {
  position: relative;
  margin: auto;
  padding: 1rem;
  max-width: 600px;
  max-height: 80vh;
  overflow: auto;
  background-color: white;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 17px;
}

.modal-close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  font-size: 1.5rem;
}
</style>
