<template>
  <Modal :is-active="isActive">
    <a class="close-modal" @click="closeModal">닫기</a>
    <div class="wrapper">
      <h2>
        <img
          src="/images/img-title-pricing.png"
          srcSet="/images/img-title-pricing@2x.png 2x"
          alt="쉽고 빠른 우리회사 PR 지금 바로 시작해보세요"
        />
      </h2>

      <ul class="pricing-info">
        <li>
          <h3>Basic</h3>
          <div>
            <img
              src="/images/img-subtitle-pricing1.png"
              srcSet="/images/img-subtitle-pricing1@2x.png 2x"
              alt="대외 홍보를 처음 시작하는 브랜드"
            />
          </div>
        </li>
        <li>
          <h3>Pro</h3>
          <div>
            <img
              src="/images/img-subtitle-pricing2.png"
              srcSet="/images/img-subtitle-pricing2@2x.png 2x"
              alt="언론을 통해 회사를 알리고 싶은 성장중인 기업"
            />
          </div>
        </li>
      </ul>

      <div class="pricing-detail">
        <table cellspacing="0" cellpadding="0">
          <tr>
            <th scope="col">Ai 보도자료 작성</th>
            <td>무제한</td>
            <td>무제한</td>
          </tr>
          <tr>
            <th scope="col">보도자료 저장</th>
            <td>무제한</td>
            <td>무제한</td>
          </tr>
          <tr>
            <th scope="col">보도자료 배포</th>
            <td>2회 / 월</td>
            <td>8회 / 월</td>
          </tr>
          <tr>
            <th scope="col">Ai 미디어 매칭</th>
            <td>250명 추천</td>
            <td>500명 추천</td>
          </tr>
          <tr>
            <th scope="col">1회 배포 기자 수</th>
            <td>최대 500명</td>
            <td>최대 1000명</td>
          </tr>
          <tr>
            <th scope="col">키워드 대시보드</th>
            <td>O</td>
            <td>O</td>
          </tr>
          <tfoot>
            <tr>
              <td>&nbsp;</td>
              <td>99,000원 <span> / 월</span></td>
              <td>290,000원<span> / 월</span></td>
            </tr>
          </tfoot>
        </table>
      </div>

      <div class="btn-group">
        <Button msg="바로 시작해보기" />
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from "@/components/common/Modal.vue";
import Button from "@/components/common/Button.vue";

export default {
  name: "ModalPricing",
  components: {
    Modal,
    Button,
  },
  props: {
    isActive: {
      type: Boolean,
      requried: true,
    },
    closeModal: {
      type: Function,
      requried: true,
    },
  },
  data() {
    return {
      activeIndex: 0,
    };
  },
  methods: {
    handleClickTab(newIndex) {
      this.activeIndex = newIndex;
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .modal-content {
  width: 860px;
  max-width: 860px;
  min-height: 860px;
  padding: 52px 126px 34px;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 17px;
  box-sizing: border-box;
  overflow: initial;

  .close-modal {
    padding-right: 40px;
    position: absolute;
    top: 10px;
    right: -88px;
    color: #fff;
    cursor: pointer;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 22px;
      height: 22px;
      background: url("../../../../public/images/img-close@2x.png") 0 0
        no-repeat;
      background-size: 22px auto;
    }
  }

  .wrapper {
    h2 {
      margin-bottom: 30px;
      text-align: center;
    }

    .pricing-info {
      display: flex;
      gap: 0 34px;
      padding-left: 148px;
      margin-bottom: 30px;

      li {
        flex: 0 0 206px;
        width: 206px;
        list-style: none;

        h3 {
          position: relative;
          margin-bottom: 18px;
          padding-bottom: 9px;
          font-weight: 700;
          font-size: 32px;
          line-height: 38px;
          text-align: center;
          letter-spacing: -0.02em;
          color: #333333;

          &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            display: block;
            width: 206px;
            height: 5px;
            background: linear-gradient(90deg, #34f19b 72.28%, #019aff 100%);
          }
        }

        div {
          text-align: center;
        }
      }
    }

    .pricing-detail {
      margin-bottom: 44px;

      table {
        width: 100%;
        table-layout: fixed;

        th,
        td {
          padding: 16px 0;
          border-top: 1px solid #999999;
          border-spacing: 0;
          text-align: center;
          color: #333333;
          letter-spacing: -0.02em;
        }

        th {
          width: 140px;
          font-weight: 700;
          font-size: 16px;
          line-height: 19px;
        }

        td {
          font-weight: 400;
          font-size: 14.8504px;
          line-height: 18px;
        }

        tfoot {
          td {
            border-top: 2px solid #999999;
            font-weight: 700;
            font-size: 19.8006px;
            line-height: 24px;

            span {
              font-size: 15px;
            }
          }
        }
      }
    }

    .btn-group {
      text-align: center;

      .btn {
        width: 440px;
      }
    }
  }
}
</style>
