<template>
  <div class="news-wrapper">
    <h4>뉴스목록</h4>
    <div class="card-list">
      <a
        :href="news.link"
        class="card"
        target="_blank"
        v-for="(news, index) in newsData"
        :key="index"
      >
        <div class="card-header">
          <div class="company-name">{{ news.comapnyName }}</div>
          <div class="repoter-name">{{ news.name }} 기자</div>
        </div>
        <div class="title">{{ news.title }}</div>
        <div class="date">{{ news.date }}</div>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "NewsList",
  props: {
    newsData: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
.news-wrapper {
  width: 300px;
  height: 626px;
  padding: 20px 26px;
  background: #ffffff;
  border: 1px solid #dbdbdb;
  border-radius: 8.8851px;
  box-sizing: border-box;
  overflow: auto;

  h4 {
    margin-bottom: 30px;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #333333;
  }

  .card-list {
    a.card {
      position: relative;
      padding: 12px 16px;
      display: block;
      margin-bottom: 30px;
      height: 98px;
      background: #ffffff;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
      border-radius: 9px;
      text-decoration: none;
      color: #333333;

      &:last-child {
        margin-bottom: 0;
      }

      .card-header {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 12px;

        .company-name {
          font-weight: 700;
          font-size: 12px;
          line-height: 14px;
        }

        .repoter-name {
          font-weight: 700;
          font-size: 8px;
          line-height: 10px;
        }
      }

      .title {
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
      }

      .date {
        position: absolute;
        bottom: 11px;
        right: 16px;
        font-weight: 700;
        font-size: 8px;
        line-height: 10px;
      }
    }
  }
}
</style>
