<template>
  <div class="chart-panel-wrapper">
    <h4>미디어노출도</h4>
    <div class="contents">
      <div class="col-contents">
        <dl class="publish-news-count">
          <dt>총 발행된 뉴스</dt>
          <dd>17</dd>
        </dl>

        <ul>
          <li>
            <dl>
              <dt>Tier1</dt>
              <dd>17</dd>
            </dl>
          </li>
          <li>
            <dl>
              <dt>Tier2</dt>
              <dd>10</dd>
            </dl>
          </li>
          <li>
            <dl>
              <dt>Tier3</dt>
              <dd>30</dd>
            </dl>
          </li>
          <li>
            <dl>
              <dt>Tier4</dt>
              <dd>30</dd>
            </dl>
          </li>
        </ul>
      </div>
      <div class="col-chart-wrapper">
        <Line :data="chartData" :options="chartOption" />
      </div>
    </div>
  </div>
</template>

<script>
import { Line } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineController,
  LineElement,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineController,
  LineElement
);

export default {
  name: "ChartPanel",
  components: {
    Line,
  },
  data() {
    return {
      chartData: {
        labels: ["1월", "2월", "3월", "4월", "5월", "6월", "7월"],
        datasets: [
          {
            label: "Tier1",
            data: [21, 20, 32, 10, 8, 18, 21],
            borderColor: "#fb6384",
            backgroundColor: "#fb6384",
            fill: true,
            tension: 0.1,
          },
          {
            label: "Tier2",
            data: [40, 62, 71, 63, 70, 78, 52],
            borderColor: "#36a2eb",
            backgroundColor: "#36a2eb",
            fill: true,
            tension: 0.1,
          },
          {
            label: "Tier3",
            data: [12, 13, 11, 9, 3, 20, 21],
            borderColor: "#4bc0c0",
            backgroundColor: "#4bc0c0",
            fill: true,
            tension: 0.1,
          },
          {
            label: "Tier4",
            data: [65, 59, 80, 81, 56, 55, 40],
            borderColor: "#ffcd57",
            backgroundColor: "#ffcd57",
            fill: true,
            tension: 0.1,
          },
        ],
      },
      chartOption: {
        responsive: true,
        // plugins: {
        //   title: {
        //     display: true,
        //     text: (ctx) =>
        //       "Chart.js Line Chart - stacked=" +
        //       ctx.chart.options.scales.y.stacked,
        //   },
        //   tooltip: {
        //     mode: "index",
        //   },
        // },
        plugins: {
          legend: {
            labels: {
              color: "#000",
              font: {
                size: 12,
                family: "Arial",
              },
              padding: 10,
            },
          },
          pointStyle: "circle", // Customize the shape of the label item
          boxWidth: 14,
        },
        interaction: {
          mode: "nearest",
          axis: "x",
          intersect: false,
        },
        scales: {
          yAxes: [
            {
              stacked: true,
            },
          ],
        },
      },
    };
  },
};
</script>

<style scoped lang="scss">
.chart-panel-wrapper {
  width: 590px;
  height: 288px;
  padding: 20px 36px;
  margin-bottom: 20px;
  background: #ffffff;
  border: 1px solid #dbdbdb;
  border-radius: 8.8851px;
  box-sizing: border-box;

  h4 {
    margin-bottom: 30px;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #333333;
  }

  .contents {
    display: flex;
    justify-content: space-between;

    .col-contents {
      flex: 0 0 124px;

      dl.publish-news-count {
        padding-bottom: 14px;
        border-bottom: 1px solid #adadad;

        dt {
          margin-bottom: 10px;
          font-weight: 700;
          font-size: 12px;
          line-height: 14px;
          color: #333333;
        }

        dd {
          font-weight: 700;
          font-size: 24px;
          line-height: 29px;
          color: #333333;
        }
      }

      ul {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 16px 10px;
        padding-top: 16px;

        li {
          list-style: none;

          dt {
            margin-bottom: 5px;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: #bfbfbf;
          }

          dd {
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: #bfbfbf;
          }
        }
      }
    }

    .col-chart-wrapper {
      width: 350px;
    }
  }
}
</style>
