<template>
  <div class="form-error-message" v-if="!!message">{{ message }}</div>
</template>

<script>
export default {
  name: "FormErrorMessage",
  props: {
    message: {
      type: String,
    },
  },
};
</script>

<style scoped lang="scss">
.form-error-message {
  padding-left: 20px;
  padding-top: 6px;
  color: #ff0000;
}
</style>
