<template>
  <Modal :is-active="isActive">
    <div class="icon-wrapper">
      <img
        srcSet="/images/ico-success-check@2x.png 2x"
        src="/images/ico-success-check.png"
        alt=""
      />
    </div>
    <h2>
      <img
        srcSet="/images/img-publish-done@2x.png 2x"
        src="/images/img-publish-done.png"
        alt="메일 배포신청이 완료되었습니다."
      />
    </h2>
    <div class="message">
      발송 결과는 담당자님의 이메일에서 확인할 수 있습니다.
    </div>

    <div class="btn-group">
      <Button msg="확인" @click="handleClickConfirm" />
    </div>
  </Modal>
</template>

<script>
import Button from "@/components/common/Button.vue";
import Modal from "@/components/common/Modal.vue";

export default {
  name: "ModalPublishDone",
  components: {
    Modal,
    Button,
  },
  props: {
    userEmail: {
      type: String,
      required: true,
    },
  },
  methods: {
    handleClickConfirm() {
      window.location = "/publish"
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .modal-content {
  width: 594px;
  max-width: inherit;
  padding-top: 40px;
  padding-bottom: 40px;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 17px;
  box-sizing: content-box;

  .icon-wrapper {
    margin-bottom: 20px;
    text-align: center;
  }

  h2 {
    margin-bottom: 20px;
    text-align: center;

    img {
      vertical-align: top;
    }
  }

  .message {
    margin-bottom: 32px;
    text-align: center;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #000000;
  }

  .btn-group {
    text-align: center;

    .btn {
      width: 344px;
    }
  }
}
</style>
