<template>
  <div class="wrapper">
    <form @submit.prevent="handleSubmit">
      <h1>
        <img src="/images/logo.svg" alt="aipr" class="main-logo-img"/>
      </h1>
      <h2>
        <img
          src="/images/txt-login.svg"
          alt="우리회사 PR, 이토록 쉬워집니다."
          class="main-logo-txt"
        />
      </h2>
      <div class="form-wrapper">
        <div class="field-wrapper">
          <Input placeholder="이메일" v-model="userId" />
        </div>
        <div class="field-wrapper">
          <Input
            type="password"
            placeholder="비밀번호"
            v-model="userPassword"
          />
        </div>
        <div class="buttons">
          <Button class="btn-login" msg="로그인"/>
          <Button class="btn-register" msg="회원가입" @click="checkSignUp"/>
        </div>
      </div>
    </form>

    <div class="utils-menu">
     <!-- <div>AiPR 계정이 없나요? <a href="#" @click="checkSignUp">가입하기123</a></div> -->
      <div>비밀번호를 잊으셨나요? <a href="#" @click="findPassword">비밀번호 찾기</a></div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import Input from "@/components/common/Input.vue";
import Button from "@/components/common/Button.vue";
import {signInAccount} from "@/firebase/firebase_auth_google";
import {signInUser} from "@/api/aipr_api";
import {assignUserData} from "@/store/store";
export default {
  name: "LoginPage`",
  setup() {
    const store = useStore();

    const userNameStore = computed(() => store.getters.userName);
    const userEmailStore = computed(() => store.getters.email);

    return {
      userNameStore,
      userEmailStore,
    };
  },
  data() {
    return {
      userId: "",
      userPassword: "",
    };
  },
  components: {
    Input,
    Button,
  },
  methods: {
    checkSignUp() {
      this.$router.push('/register');
    },
    findPassword() {
      this.$router.push('/find-password');
    },
    async handleSubmit() {
      const googleAuthUser = await signInAccount(this.userId, this.userPassword);
      if(googleAuthUser){
        const res = await signInUser(this.userId, googleAuthUser.uid);
        if(res.result === "success"){
          assignUserData(this.$store, res.data);
          this.$router.push('/publish');
        }else{
          alert(res.message)
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">

@media (max-width: 480px) {
  .main-logo-img {
    width: 50vw;
  }
  .main-logo-txt {
    width: 90vw;
  }
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    background: #333333;

    img {
      vertical-align: top;
    }

    h1 {
      margin-bottom: 30px;
    }

    h2 {
      margin-bottom: 7vh;
    }

    .form-wrapper {
      width: 90vw;
      margin: 0 auto;

      .field-wrapper {
        margin-bottom: 16px;
      }
    }

    .btn-login {
      width: 45%;
      height: 7vh;
      border-radius: 100px;
      margin: 2vw 3vw 0 0;
      line-height: 30px;
      text-align: center;
      letter-spacing: -0.02em;
      color: #000000;
      background: linear-gradient(90deg, #34f19b 72.28%, #019aff 100%);
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    }

    .btn-register {
      width: 45%;
      height: 7vh;
      border-radius: 100px;
      margin: 2vw 0 0 3vw;
      line-height: 30px;
      text-align: center;
      letter-spacing: -0.02em;
      color: #000000;
      background-color: #e0e0e0;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    }

    .utils-menu {
      margin-top: 5vh;

      > div {
        display: flex;
        gap: 0 24px;
        margin-bottom: 10px;
        justify-content: center;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        letter-spacing: -0.02em;
        color: #ffffff;

        a {
          color: #24a0ed;
          text-decoration: underline;
          letter-spacing: -0.02em;
          text-decoration-line: underline;
        }
      }
    }
  }
}

@media (min-width: 481px) {
  .main-logo-img {
    width: 35vh;
  }
  .main-logo-txt {
    width: 50vh;
  }
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    background: #333333;

    img {
      vertical-align: top;
    }

    h1 {
      margin-bottom: 30px;
    }

    h2 {
      margin-bottom: 7vh;
    }

    .form-wrapper {
      width: 60vh;
      margin: 0 auto;

      .field-wrapper {
        margin-bottom: 16px;
      }
    }

    .btn-login {
      max-width: 270px;
      width: 13vw;
      height: 7vh;
      border-radius: 100px;
      margin-top: 2vh;
      margin-right: 10px;
      line-height: 30px;
      text-align: center;
      letter-spacing: -0.02em;
      color: #000000;
      background: linear-gradient(90deg, #34f19b 72.28%, #019aff 100%);
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    }
    .buttons{
      width: 100%;
      justify-content: stretch;
    }
    .btn-register {
      max-width: 270px;
      height: 7vh;
      width: 13vw;
      border-radius: 100px;
      margin-top: 2vh;
      margin-left: 10px;
      line-height: 30px;
      text-align: center;
      letter-spacing: -0.02em;
      color: #000000;
      background-color: #e0e0e0;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    }

    .utils-menu {
      margin-top: 9vh;

      > div {
        display: flex;
        gap: 0 24px;
        margin-bottom: 10px;
        justify-content: center;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        letter-spacing: -0.02em;
        color: #ffffff;

        a {
          color: #24a0ed;
          text-decoration: underline;
          letter-spacing: -0.02em;
          text-decoration-line: underline;
        }
      }
    }
  }
}
</style>
