import { createApp } from "vue";
import { createRouter, createWebHistory } from "vue-router";
import store from "./store/store";
import App from "./App.vue";
import Entry from "./components/pages/Entry/Entry.vue";
import Login from "./components/pages/Login/Login.vue";
import FindPassword from "./components/pages/FindPassword/FindPassword.vue";
import Register from "./components/pages/Register/Register.vue";
import CreatePR from "./components/pages/CreatePR/CreatePR.vue";
import CreatePRModalPricing from "./components/pages/CreatePRModalPricing/CreatePRModalPricing.vue";
import PublishPage from "./components/pages/Publish/PublishPage.vue";
import Dashboard from "./components/pages/Dashboard/Dashboard.vue";
import DashboardV1 from "./components/pages/DashboardV1/DashboardV1.vue";
import { VueFire, VueFireAuth } from 'vuefire'
import {firebaseApp} from "@/firebase/firebase_app";
import MainEntry from "@/components/pages/Entry/MainEntry";
import * as Sentry from "@sentry/vue";

const routes = [
  { path: "/", redirect: '/main' },
  {
    path: "/entry",
    component: Entry,
  },
  {
    path: "/main",
    component: MainEntry,
  },
  {
    path: "/find-password",
    component: FindPassword,
  },
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/register",
    component: Register,
  },
  {
    path: "/create/:pressReleaseId?",
    component: CreatePR,
    props: true,
    meta:{requiresAuth: true}
  },
  {
    path: "/modal-pricing",
    component: CreatePRModalPricing,
  },
  {
    path: "/publish",
    component: PublishPage,
    props: true,
    meta:{requiresAuth: true}
  },
  {
    path: "/dashboard",
    component: Dashboard,
    meta:{requiresAuth: true}
  },
  {
    path: "/dashboard/v1",
    component: DashboardV1,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

const app = createApp(App);

Sentry.init({
  app,
  environment: process.env.NODE_ENV,
  dsn: "https://3146b3bf8611411c802f0fcbd11ede10@o4505140750385152.ingest.sentry.io/4505140752089088",
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

app.use(router);
app.use(store);

app.use(VueFire, {
  firebaseApp: firebaseApp,
  modules: [
    // ... other modules
    VueFireAuth(),
  ],
})
app.mount("#app");
