<template>
  <div class="checkbox-wrapper">
    <input
      type="checkbox"
      :id="id"
      :checked="checked"
      @change="toggleChecked"
    />
    <label :for="id">{{ label }}</label>
  </div>
</template>

<script>
export default {
  name: "CustomCheckbox",
  props: {
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    value: {
      type: Boolean,
    },
  },
  computed: {
    checked() {
      return this.value;
    },
  },
  methods: {
    toggleChecked(event) {
      this.$emit("input", event.target.checked);
    },
  },
};
</script>

<style scoped>
.checkbox-wrapper {
  text-align: left;
}

.checkbox-wrapper input {
  width: 19px;
  height: 19px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  border-color: #eee;
  vertical-align: middle;
}
.checkbox-wrapper label {
  margin-left: 10px;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: -0.02em;
  color: #000000;
  vertical-align: middle;
}
</style>
