<template>
  <Layout>
    <div class="wrapper">
      <div class="header-group">
        <h2>뉴스 분석 대시보드</h2>
        <Input
          v-model="keyword"
          style="width: 200px"
          placeholder="나의 키워드"
        />
      </div>

      <div class="contents">
        <div class="date-filter-wrapper">
          <a href="#" class="btn-calendar"></a>
          <div class="date">최근 8일 | 2023년 09일 ~ 16일</div>
        </div>

        <div class="panel-wrapper">
          <div class="col col1">
            <div class="row">
              <ChartPanel />
            </div>
            <div class="row">
              <Ranking
                type="media"
                :ranking-data="mediaData"
                :total-count="18"
                :handle-click-prev="fetchRepoterRank"
                :handle-click-next="fetchRepoterRank"
              />
              <Ranking
                type="reporter"
                :ranking-data="repoterData"
                :total-count="32"
                :handle-click-prev="fetchRepoterRank"
                :handle-click-next="fetchRepoterRank"
              />
            </div>
          </div>
          <div class="col col2">
            <NewsList :news-data="newsData" />
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Input from "@/components/common/Input.vue";
import Layout from "@/components/common/Layout.vue";
import NewsList from "./NewsList.vue";
import Ranking from "./Ranking.vue";
import ChartPanel from "./ChartPanel.vue";

export default {
  name: "DashboardV1",
  components: {
    Layout,
    Ranking,
    NewsList,
    Input,
    ChartPanel,
  },
  data() {
    return {
      keyword: "",
      newsData: [
        {
          comapnyName: "비석세스",
          name: "배민욱",
          title: "LGU+, 갤럭시S22 시리즈 공시지원금 최대 60만원",
          date: "2023-03-21",
          link: "https://www.naver.com/",
        },
        {
          comapnyName: "비석세스",
          name: "배민욱",
          title: "LGU+, 갤럭시S22 시리즈 공시지원금 최대 60만원",
          date: "2023-03-21",
          link: "https://www.naver.com/",
        },
        {
          comapnyName: "비석세스",
          name: "배민욱",
          title: "LGU+, 갤럭시S22 시리즈 공시지원금 최대 60만원",
          date: "2023-03-21",
          link: "https://www.naver.com/",
        },
      ],
      repoterData: [
        {
          rank: 1,
          repoter: "배민욱",
          company: "뉴시스",
          articleCount: 3,
        },
        {
          rank: 2,
          repoter: "배민욱",
          company: "뉴시스",
          articleCount: 3,
        },
        {
          rank: 3,
          repoter: "배민욱",
          company: "뉴시스",
          articleCount: 3,
        },
        {
          rank: 4,
          repoter: "배민욱",
          company: "뉴시스",
          articleCount: 3,
        },
        {
          rank: 5,
          repoter: "배민욱",
          company: "뉴시스",
          articleCount: 3,
        },
      ],
      mediaData: [
        {
          rank: 1,
          company: "비석세스",
          articleCount: 3,
        },
        {
          rank: 2,
          company: "매일경제",
          articleCount: 3,
        },
        {
          rank: 3,
          company: "한국경제",
          articleCount: 3,
        },
        {
          rank: 4,
          company: "머니투데이",
          articleCount: 3,
        },
        {
          rank: 5,
          company: "뉴시스",
          articleCount: 3,
        },
      ],
    };
  },
  methods: {
    fetchRepoterRank(page) {
      // TODO: fetch data
      console.log(page);
    },
    fetchMediaRank(page) {
      // TODO: fetch data
      console.log(page);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.wrapper {
  min-width: 930px;
  padding: 73px 140px;

  .header-group {
    display: flex;
    align-items: center;
    margin-bottom: 73px;

    h2 {
      margin-right: 40px;
      font-weight: 900;
      font-size: 28px;
      line-height: 33px;
      color: #333333;
    }
  }

  .date-filter-wrapper {
    display: flex;
    align-items: center;

    width: 340px;
    height: 40px;
    padding-left: 16px;
    margin-bottom: 20px;
    box-sizing: border-box;
    background: #ffffff;

    a.btn-calendar {
      display: block;
      width: 20px;
      height: 20px;
      margin-right: 40px;
      background: url("../../../../public/images/ico-calendar@2x.png") center
        no-repeat;
      background-size: 20px auto;
    }

    .date {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #24a0ed;
    }
  }

  .panel-wrapper {
    display: flex;
    gap: 20px;
    .col {
      &.col1 {
        .row {
          display: flex;
          justify-content: space-between;
          gap: 20px;
        }
      }
    }
  }
}
</style>
